html {
  font-size: 16px;
}

body {
  background-color: $white;
  font-family: $font;
  // font-size: 1.125rem;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: map-get($grays, "900");
}

.text-blue {
  color: map-get($colors, "blue") !important;
}
.text-black {
  font-weight: 900;
}
.worttrennung  {
  hyphens: auto;
}
.sanoa-blue {
  color: map-get($theme-colors, "sanoa-blue") !important;
  a {
    color: map-get($theme-colors, "sanoa-blue") !important;
    &:hover {
      color: map-get($theme-colors, "sanoa-blue") !important;
    }
  }
}
.sanoa-green {
  color: map-get($theme-colors, "sanoa-green") !important;
  a {
    color: map-get($theme-colors, "sanoa-green") !important;
    &:hover {
      color: map-get($theme-colors, "sanoa-green") !important;
    }
  }
}

.app_root,
.ida-main {
  min-height: 100vh;
}
.wrap {
  // box-shadow: 0px 0px 15px -8px #000000;
  min-height: 100vh;
}

.single-page {
  .app_root,
  .ida-main,
  .wrap {
    min-height: auto;
  }
}
.container-padding {
  padding-left: $contaner-mobile-padding;
  padding-right: $contaner-mobile-padding;
  @include media-breakpoint-up(lg) {
    padding-left: $contaner-padding;
    padding-right: $contaner-padding;
  }
}

.container-margin {
  margin: $container-margin;
}

.container {
  width: 100%;
  padding-right: $contaner-mobile-padding;
  padding-left: $contaner-mobile-padding;
  //margin-right: 15px;
  //margin-left: 15px;
  max-width: 824px;
  // 992px;
  @include media-breakpoint-up(lg) {
    margin-right: auto;
    margin-left: auto;
  }
  // 1360px;
  @include media-breakpoint-up(xl) {
    max-width: calc(824px + 482px + 1rem);
    margin-right: 15px;
    margin-left: auto;
  }
  //860px;
  @include media-breakpoint-up(xxl) {
    margin-right: auto;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  color: $gray-dark;
}
.single-sanoa_brand {
  h1,
  h4,
  h5 {
    color: var(--brand-primary);
  }
}

h1 {
  font-size: 1.125rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}
h2 {
  font-size: 1.125rem;
  padding: 0 0 0 0.7rem;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
  @include custom_colors($prop: color, $color: primary);
}

h3 {
  font-weight: 600;
  font-size: 1.125rem;
  padding: 0 0 0 0.7rem;
  margin-bottom: 0.8rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

h4 {
  font-size: 1.1rem;
  font-weight: 600;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

strong,
b {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 600 !important;
}

/* see https://stackoverflow.com/questions/10760243/safari-image-size-auto-height-css */
.img-fluid,
.img-thumbnail {
  align-self: flex-start; /* for Safari, avoid stretching */
}

.ida-main {
  display: block;
  width: 100%;
  // @extend .col-12;
  // @extend .col-xxl-9;
  // @extend .offset-xxl-3;

  // padding-top: 7rem;
  ul {
    list-style: none;
    padding: 1rem 0 0.5rem 1.2rem;
    li {
      position: relative;
      margin: 0 0 0.5rem 0;
      // color: map-get($colors,'indigo');
      // font-weight: 700;
    }
    > li::before {
      position: absolute;
      content: "\2013";
      //color: map-get($colors, 'indigo');
      color: inherit;
      display: inline-block;
      width: 1.2rem;
      left: -1.2rem;
      line-height: 1;
      font-size: 1.5rem;
    }
  }
}
.ida-content {
  position: relative;
  margin-bottom: 100px; // for the fixed footer
  &.page {
    margin-top: 10rem;
  }
}
.fill-partner-color {
  //fill: var(--partner-primary);
  @include custom_colors($prop: fill, $color: primary);
}

// global classes
.-shadow {
  margin: $box-shadow-width 0;
  box-shadow: $box-shadow;
  border-radius: 0.7rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
}

.-icon-arrow-right {
  position: relative;
  display: inline-block;
  padding-right: 1.5rem;
  color: map-get($colors, "primary");
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1.188rem;
    background-image: $icon-arrow-right;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}

// ----------------------------------------------------------------
// box with rounded border and collapsed contend
// ----------------------------------------------------------------
.mink-collapse-box {
  margin: 0 0 1.5rem 0;
  @include media-breakpoint-up(md) {
    margin: 0 0 2rem 0;
  }
  &__content {
    padding: 1rem;
    border-width: $border-width;
    border-style: solid;
    border-radius: $border-radius;
    border-color: $primary;
    @include custom_colors($prop: border-color, $color: primary);
  }
  &__toggler {
    display: flex;
    justify-content: flex-end;
    color: $primary;
  }
  &__button {
    @extend .btn-reset;
    position: relative;
    display: inline;
    height: 1.5rem;
    margin: 0;
    padding: 0 1.5rem 0 0;
    color: $primary;
    font-size: 0.75rem;
    font-weight: 600;
    user-select: none;
    &::after {
      content: "";
      position: absolute;
      top: 0.2rem;
      right: 0;
      width: 1.063rem;
      height: 1.063rem;
      display: inline-block;
      background: no-repeat center;
      background-image: $icon-collapse-open;
    }
    &.open {
      &::after {
        background-image: $icon-collapse-close;
      }
    }
    &:focus {
      border: none;
      outline: none;
      color: $primary;
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.mink-dropdown {
  padding: 0.5rem;
  background-color: $white;
  border: 0.125rem solid map-get($theme-colors, "sanoa-blue");
  border-radius: $border-button-radius;
  font-family: $font-portal;
  color: map-get($theme-colors, "sanoa-blue");
  &__toggle {
    position: relative;
    cursor: pointer;
    font-weight: 700;

    &::after {
      content: "";
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      background-image: $icon-chevron-up-blue;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(180deg);
      transition: transform 0.125s;
    }
    &.-active {
      &::after {
        transform: rotate(0);
      }
    }
  }
  &__container {
    height: 0;
    overflow: hidden;
    transition: transform 0.125s;
    &.-active {
      height: 100%;
    }
  }
  &__item {
    position: relative;
    margin: 0 -0.5rem 0.5rem -0.5rem;
    padding: 0 0.5rem;
    input {
      position: absolute;
      left: -2rem;
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 1.5rem);
      margin-left: 1.5rem;
      &::before {
        content: "";
        position: absolute;
        left: -1.5rem;
        height: 100%;
        width: 1rem;
        // background-image: $icon-check;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      span {
        display: block;
        order: 2;
        font-size: 0.8rem;
        // font-weight: 600;
      }
      img {
        order: 1;
        width: 3rem;
        height: 100%;
        margin-right: 0.5rem;
      }
    }
    &:hover {
      label {
        &::before {
          display: none;
        }
      }
      span {
        font-weight: 600;
      }
    }
    &.-active {
      // background-color: map-get($theme-colors, "sanoa-color");
      label {
        &::before {
          background-image: $icon-check;
        }
      }
      span {
        font-weight: 700;
      }
    }
    &.-active {
      &:hover {
        label {
          &::before {
            background-image: $icon-check;
          }
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
}
