// -----------------------------------------------------------------
// ACF partner locations: list with all locations of current partner
// -----------------------------------------------------------------
.mink-partner-locations {
  @extend .container-margin;
  &__header {
    @include custom_colors($prop: color, $color: primary);
    position: relative;
    padding-left: 2rem;
    &::after {
      content: "";
      position: absolute;
      left: 0.6rem;
      top: 0.2rem;
      width: 0.813rem;
      height: 1.125rem;
      background-color: $primary;
      @include custom_colors($prop: background-color, $color: primary);
      mask-image: $icon-map-marker;
    }
  }
  &__content {
    padding: 1rem;
    border-width: $border-width;
    border-style: solid;
    border-radius: $border-radius;
    border-color: $primary;
    @include custom_colors($prop: border-color, $color: primary);
    color: $primary;
    ul {
      font-weight: 600;
    }
  }
  ul {
    padding: 0 1rem;
    margin: 0 0 0 0;
  }
}
