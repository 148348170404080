// -----------------------------------------------------------------
// ACF On Page Menu: generates hash link items
// -----------------------------------------------------------------
.mink-hash-menu {
  border-radius: $border-radius;
  border: $border-menu;
  margin: 0 0 2rem 0;
  &__header {
    border-bottom: $border-menu;
    padding: 0.8rem 1rem 0.8rem 0;
    h3 {
      margin-bottom: 0;
      padding: 0 0 0 0.7rem;
      @include custom_colors($prop: color, $color: primary);
    }
  }
  ul {
    padding: 0;
    margin-bottom: 0;
    .-item {
      font-weight: 600;
      border-bottom: $border-menu;
      margin: 0;
      padding: 0 0 0 0.7rem;
      &:last-child {
        border-bottom: none;
      }
      &::before {
        content: none;
      }
      .-link {
        position: relative;
        padding: 0.6rem 0 0.6rem 1.5rem;
        height: 2.75rem;
        // line-height: 2.2;
        display: inline-block;
        width: 100%;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 1.188rem;
          height: 1.188rem;
          top: 0.8rem;
          left: 0;
          margin-right: 0.7rem;
          background: $icon-arrow-right-blue no-repeat center;
          transform: rotate(90deg);
        }
      }
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-hash-menu {
    border-radius: $border-radius;
    border: none;
    margin: 0 0 3rem 0;
    @include media-breakpoint-up(lg) {
      width: 66%;
    }
    &__header {
      border-bottom: none;
      padding: 0.8rem 1rem 0.8rem 0;
      h3 {
        margin-bottom: 0;
        padding: 0 0 0 0;
      }
    }
    ul {
      padding: 0;
      margin-bottom: 0;
      .-item {
        font-weight: 700;
        border-bottom: none;
        margin: 0;
        padding: 0 0 0 0;
        color: map-get($theme-colors, "sanoa-green-portal");
        &:last-child {
          border-bottom: none;
        }
        &::before {
          content: none;
        }
        .-link {
          position: relative;
          color: map-get($theme-colors, "sanoa-green-portal");
          padding: 0.6rem 0 0.6rem 1.5rem;
          height: 2.75rem;
          // line-height: 2.2;
          display: inline-block;
          width: 100%;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 1.188rem;
            height: 1.188rem;
            top: 0.8rem;
            left: 0;
            margin-right: 0.7rem;
            background: $icon-arrow-right-sanoa-green no-repeat center;
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  .mink-fc-anchor {
    @include media-breakpoint-up(lg) {
      width: 66%;
    }
  }
}
