.sn-portal {
  font-family: $font-portal;
  letter-spacing: -0.013rem;
  color: map-get($theme-colors, "sanoa-primary");
  font-weight: 500;
  .container {
    width: 100%;
    padding-right: $contaner-mobile-padding;
    padding-left: $contaner-mobile-padding;
    // max-width: 824px;
    max-width: 100%;
    // 992px;
    @include media-breakpoint-up(lg) {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
    // 1360px;
    @include media-breakpoint-up(xl) {
      max-width: calc(824px + 482px + 1rem);
      margin-right: auto;
      margin-left: auto;
    }
    //1920px;
    @include media-breakpoint-up(xxl) {
      margin-right: auto;
    }
  }
  .sn-link {
    color: map-get($theme-colors, "sanoa-primary");
    text-decoration: underline;
    text-decoration-color: map-get($theme-colors, "sanoa-orange");
    text-decoration-style: solid;
    text-decoration-thickness: $border-width;
    text-underline-offset: 0.25rem;
  }
  //header
  &__header {
    @include media-breakpoint-up(md) {
      position: fixed;
      width: 100%;
      top: 0;
      display: block;
      z-index: 100;
      background: $white;
    }
    transition: all 0.25s;
    &.-scrolled {
      box-shadow: $box-shadow;
      transform: translateY(-2rem);
    }
  }
  &__teaser {
    padding: 0.5rem 0;
    font-size: 0.75rem;
    text-align: right;
    background-color: map-get($theme-colors, "sanoa-secondary");
    transition: all 0.1s ease-out;
    a {
      color: map-get($theme-colors, "sanoa-primary");
      text-decoration: underline;
    }
    .-scrolled & {
      transform: translateY(-2rem);
    }
  }
  &__nav {
    position: relative;
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      // align-items: start;
    }
    .menu-toggler {
      position: absolute;
      top: 1rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      background-image: $icon-menu-toggler;
      background-size: contain;
      background-repeat: no-repeat;
      @include media-breakpoint-up(md) {
        display: none;
      }
      &.is-open {
        background-image: $icon-menu-toggler-close;
      }
    }

    .logo {
      margin: 1rem 0;
      width: 7rem;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      @include media-breakpoint-up(lg) {
        width: 12rem;
        margin: 2rem 0;
        .-scrolled & {
          margin: 1rem 0;
        }
      }
    }
    .claim {
      font-size: 0.75rem;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }
  &__main-menu {
    display: none;
    margin: 3.5rem 0 1rem 0;
    margin-left: auto !important;
    padding: 0 !important;
    list-style: none;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      display: flex;
      margin: 2rem 0;
      flex-direction: row;
      .-scrolled & {
        margin: 1rem 0;
      }
    }
    &.is-open {
      display: flex;
    }
    .nav-item {
      padding: 0.5rem 0 0.5rem 1rem;
      text-align: right;
      &::before {
        display: none !important;
      }
      @include media-breakpoint-up(md) {
        padding: 0 0 0 1rem;
        &:first-child {
          margin: 0;
          padding: 0;
          border: none;
          a,
          button {
            border: none;
            border-radius: 0;
          }
        }
      }
      a,
      button {
        @extend .sn-menu-btn;
      }
      .current-menu-item {
        a {
          @extend .sn-link;
        }
      }
    }
  }
  &__sub-menu {
    display: none;
    box-shadow: inset 0 7px 0.7rem -7px rgba(0, 0, 0, 0.15), inset 0 -6px 0.7rem -7px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease-in-out;
    @include media-breakpoint-up(md) {
      display: block;
    }
    &.is-open {
      display: block;
    }

    .sn-flyout {
      transition: all 0.2s ease-in-out;
      height: 0;
      &.show {
        height: auto;
      }
      &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 0 1rem 0;
        padding: 1rem 0;
        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: center;
        }
      }
      &__close {
        position: absolute;
        text-indent: -9999em;
        top: 1rem;
        right: 0;
        width: 2rem;
        height: 2rem;
        background-image: $icon-menu-toggler-close;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .sub-menu-heading {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 33%;
        padding-right: 1rem;
      }
      .sub-menu-name {
        @extend h4;
      }
    }
    ul {
      display: flex;
      margin: 3.5rem 0 1rem 0;
      padding: 0 !important;
      list-style: none;
      flex-direction: column;
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 66%;
        margin: 2rem 0;
        border-left: $border-width solid map-get($theme-colors, "sanoa-primary");
        // flex-direction: row;
        .-scrolled & {
          margin: 1rem 0;
        }
      }
      ul {
        border: none;
        margin: 1rem 0 1rem 0;
      }
    }
    li {
      padding: 0 0 0 1rem;
      &::before {
        display: none !important;
      }
      @include media-breakpoint-up(md) {
      }
      a,
      button {
        @extend .sn-menu-btn;
        border: none;
      }
      li {
        a,
        button {
          font-weight: 500;
        }
      }
      .current-menu-item {
        a {
          @extend .sn-link;
        }
      }
      .sn-collapse {
        padding: 1rem 0 0 0;
        @include media-breakpoint-up(md) {
          padding: 1rem;
        }
        .sub-menu {
          margin: 0;
          padding: 0;
        }
        li {
          margin: 0 0 0.5rem 0;
        }
        a {
          border-left: none;
          padding: 0;
          line-height: 1.5;
          font-weight: 500;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    font-weight: 900;
    color: map-get($theme-colors, "sanoa-primary");
  }

  h1 {
    font-size: 1.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    &.mink-fc-anchor {
      color: map-get($theme-colors, "sanoa-green-portal");
    }
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
  h5,
  h6 {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(lg) {
    }
  }
  .-shadow {
    margin: 0.3rem 0;
    box-shadow: 0 0 0.3rem rgba($black, 0.15);
    border-radius: 0;
    padding-bottom: 0.5rem;
    overflow: hidden;
  }
  strong,
  b {
    font-weight: 700;
  }

  .-post-content {
    @include media-breakpoint-up(md) {
      margin-top: 10rem;
    }
  }

  &__footer {
    margin-bottom: 2rem;
    width: 100%;
    .footer-box {
      background-color: map-get($theme-colors, "sanoa-primary");
      height: 20rem;
    }
    .footer-navi {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        position: relative;
        font-weight: 600;
        color: map-get($theme-colors, "sanoa-primary");
        &:hover {
          @extend .sn-link;
        }
      }
    }
    .footer-navi-item {
      margin-top: 0.5rem;
      a {
        padding: 0 0.5rem;
        border-left: $border-width solid map-get($theme-colors, "sanoa-primary");
      }
      &:first-child {
        a {
          border: none;
        }
      }
    }
  }
}
