// ----------------------------------------------------------------
// ACF Teaser: Text, Image, Link
// ----------------------------------------------------------------
.acf-teaser {
  display: flex;
  // flex-direction: column;
  flex-direction: row;
  margin-bottom: 2.5rem;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
  &:last-child {
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 0.875rem;
    font-style: normal;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  &__list {
    &.-seperator {
      border-top: 0.063rem solid var(--brand-primary, var(--partner-primary));
      border-bottom: 0.063rem solid var(--brand-primary, var(--partner-primary));
      padding-top: 1rem;
    }
    margin-bottom: 1.5rem;
  }

  &__img {
    width: 6.25rem;
    height: 6.25rem;
    @include media-breakpoint-up(md) {
      width: 6.25rem;
      height: 6.25rem;
    }

    @include media-breakpoint-up(lg) {
      width: 12.5rem;
      height: 12.5rem;
    }
    img {
      object-fit: cover;
      width: 6.25rem;
      height: 6.25rem;

      @include media-breakpoint-up(md) {
        width: 6.25rem;
        height: 6.25rem;
      }
      @include media-breakpoint-up(lg) {
        width: 12.5rem;
        height: 12.5rem;
      }
    }
  }
  &__text {
    h2,
    h3,
    h4 {
      padding-left: 0;
    }
    width: 100%;
    padding: 0 0 0 0.625rem;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 1.313rem;
    }
  }
  .-head {
    font-weight: 600;
    color: $gray-900;
    font-size: 1rem;
  }
  .-text {
    margin-bottom: 1rem;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .-link-wrapper {
    flex: 0 0 auto;
    height: 1.7rem;
    margin: 0;
  }
  .-link {
    font-weight: 600;
    // text-align: right;
    a {
      @extend .-icon-arrow-right;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0.5rem;
      a {
        padding: 0.2rem 0.5rem;
        border-radius: 0.2rem;
        color: $white;
        background-color: var(--brand-primary, var(--partner-primary));
        &::after {
          display: none;
        }
      }
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------

.sn-portal {
  .acf-teaser {
    &__list {
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
      &.-seperator {
        border-top: 0.125rem solid map-get($theme-colors, "sanoa-primary");
        border-bottom: 0.125rem solid map-get($theme-colors, "sanoa-primary");
        padding-top: 1rem;
      }
      margin-bottom: 1.5rem;
    }

    h6 {
      font-size: 0.875rem;
      font-style: normal;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    &__text {
      a {
        @extend .sn-link;
        position: relative;
        font-weight: 600;
      }
    }
    .-link-wrapper {
      flex: 0 0 auto;
      height: auto;
      margin: 0;
    }
    .-link {
      font-weight: 600;
      text-align: left;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      a {
        @extend .sn-link;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        &::after {
          display: none;
        }
      }
    }
  }
}
