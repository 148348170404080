// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="number"],
input[type="date"],
textarea,
select {
  background-color: $gray-250;
  border: 0;
  border-bottom: 0.1rem solid $gray-900;
  width: 100%;
  padding: 0.4rem 0.3rem 0.3rem 0.3rem;
}

// sanoa forms
.mink-form {
  h2,
  h3 {
    padding: 0;
  }
  label {
    padding: 0;
    margin: 0;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  input[type="number"],
  input[type="checkbox"],
  .mink-reason-checkbox,
  .mink-contact-checkbox,
  textarea,
  select {
    margin: 0 0 1rem 0;
  }
  .wpcf7-list-item {
    margin: 0;
  }
  &__checkbox {
    label {
      position: relative;
      display: flex;
      @extend .custom-control;
      @extend .custom-checkbox;
      user-select: none;
      padding: 0 0 0 2.3rem;
    }
    .wpcf7-list-item-label {
      @extend .custom-control-label;
      &::before {
        position: absolute;
        top: 0.25rem;
        left: -2.3rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        pointer-events: none;
        content: "";
        background-color: $gray-250;
        border: $gray-900 solid 0.063rem;
        border-radius: 0.25rem !important;
      }
    }
    .custom-checkbox {
      input[type="checkbox"]:checked ~ .wpcf7-list-item-label::after {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACFUlEQVR4nLXQT0iTcRjA8e/zm27+DRtWW40sPVQK7jAWM4hGHYqd7BZ5tEOSC4MYOOgS2GEZRKNEDAIvQtQp8RAEC4IS/5QVoaQROBuRrtIYbNl+XfKw3N4Je32u7/N+P/weAfCPBV1pTUSBX2vtpIQRkUQWYjYhFAtE4+IfC7oymhm0tpcSziMlrYJbpTUR0+MAWtvTmohS4Dc9/m8U+FWpNzcarbVTbVd8Y0wDdpRXccNzgbMNx80HbKqcfm8XJxxuvPWHzAUsoujzdNKy8wBfUsvcev/QXCDc2kHb7ha+p9e4Mn6PlfSqecClw+2ccR0ltZ7m6sQA8dS3TTs5wE3vRQbaeqiz1hSNnzt4kvNNp1jP/iE8NcTsz8W8ezlATVkFrfYmor6gIXJ6n5fuI+1orbn+ZpiJ5bmCuzlA79R9Pq0laKzdWxDx7Wom3NqBiHD7w2OeJaYNX5oD/Mj8IvjqTkGkua6BPk8nZcrC8PxTHn1+bhjfBBgh+6v30O/tosJiZXTxJYNzT4rGAeTYaLfO96HOWkPUd5nGWievV+ZxVtlxVNp58fUdvZNDZMn7W/EX/P+SieVZXNX1OCrtvE0ucG36wZbjhsAG0jN+l4+rS8wkFwhNDpLJ/t5yHAxOZNYoEUlsV1xEEioLse0CshBTNiGESNL0ukjSJoRULBCNWwW3Fhkx41wiktAiI1bBHQtE438BfALA4aQPECkAAAAASUVORK5CYII=");
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0.25rem;
        left: -2.3rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        pointer-events: none;
        content: "";
        background-color: $gray-250;
        border: $gray-900 solid 0.063rem;
        border-radius: 0.25rem !important;
      }
    }

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 1rem;
      height: 1.25rem;
      opacity: 0;
    }
  }
  select {
    max-width: 100%;
  }
  input[type="submit"] {
    @extend .btn-cta;
    width: auto;
    margin: 0 auto 1rem;
  }
}

// recipe && bewertung
.page-template-template-formular,
.page-template-template-review,
.page-template-template-review-form {
  .ida-page {
    &__content {
      > .row {
        margin-top: 0 !important;
      }
      .page-header {
        display: none;
      }
    }
  }
  .content {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
  form {
    border: none;
    // margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
  }
}

.mink-form {
  // default margin
  // @ToDo move to var
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
  &.-review {
    .survey {
      .answer {
        width: 5rem;
        height: 5rem;
        background-size: cover;
        background-position: center;
        margin: 1.25rem;
      }
      .-positive {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 50.3 44.9' viewBox='0 0 50.3 44.9'%3E%3Cpath d='M14 43H7.1c-1.9 0-3-1-3-3V22.7c0-1.9 1.1-3 3-3h8.5c1.1 0 1.9.4 2.6 1.2-3.8 5.8-4.4 13.1-1 19.1-.1.1-1 3-3.2 3z' style='fill:%233ab46c;stroke:%233ab46c;stroke-width:3.15;stroke-miterlimit:10'/%3E%3Cpath d='M44.7 29.8c1.7 3.6 1 5.4-2.8 6.9.1.9.2 1.8.2 2.6-.2 2-1.6 3.3-3.7 3.3-5 .1-10 .1-15-.1-1.4 0-4.4-1.9-6.1-2.6-3.4-6-3.1-13.3.8-19 .5.6 1.5.6 1.9 0 1.7-2.6 3.7-5.1 5.1-7.9.9-1.8 1.2-4 1.6-6 .3-1.2.4-2.6.6-3.8.3-1.6 1.5-2.2 3-1.6 3.1 1.2 4.9 4.3 4.2 7.6-.5 2.6-.9 5.1-1.4 7.9h9.4c2.6.1 4.2 2.2 3.5 4.6-.2.6-.6 1.2-1 1.8 1.8 2.5 1.7 4.2-.3 6.3z' style='fill:none;stroke:%233ab46c;stroke-width:1.7;stroke-miterlimit:10'/%3E%3C/svg%3E");
        a {
          display: inline-block;
          text-indent: -9999em;
          width: 5rem;
          height: 5rem;
        }
      }
      .-negative {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 50.3 44.9' viewBox='0 0 50.3 44.9'%3E%3Cpath d='M36.4 2.2h6.9c1.9 0 3 1 3 3v17.3c0 1.9-1.1 3-3 3h-8.5c-1.1 0-1.9-.4-2.5-1.2 3.8-5.8 4-14.4.6-20.4-.1-.1 1.3-1.7 3.5-1.7z' style='fill:%23ff566b;stroke:%23ff566b;stroke-width:3.7;stroke-miterlimit:10'/%3E%3Cpath d='M5.6 15.3c-1.7-3.6-1-5.4 2.8-6.9-.1-.8-.2-1.7-.2-2.6.2-2 1.6-3.3 3.7-3.3 5-.1 10-.1 15 .1 1.4 0 4.4 1.9 6 2.6 3.4 6 3.1 13.3-.8 19-.5-.6-1.5-.6-1.9 0-1.7 2.6-3.7 5.1-5.1 7.9-.9 1.8-1.2 4-1.6 6-.3 1.2-.4 2.5-.6 3.8-.3 1.6-1.5 2.2-3 1.6-3.1-1.2-4.9-4.3-4.2-7.6.5-2.6.9-5.1 1.4-7.9H7.7c-2.6-.1-4.2-2.2-3.5-4.6.2-.6.6-1.2 1-1.8-1.7-2.5-1.6-4.1.4-6.3z' style='fill:none;stroke:%23ff566b;stroke-width:1.7;stroke-miterlimit:10'/%3E%3C/svg%3E");
        .mink-opinion-checkbox {
          input {
            appearance: none;
            cursor: pointer;
            width: 5rem;
            height: 5rem;
          }
        }
      }
      .wpcf7-list-item-label {
        display: none;
      }
    }
    .mink-reason-checkbox {
      display: flex;
      flex-direction: column;
      .wpcf7-list-item {
        margin-bottom: 10px;
      }
    }
    input[type="submit"] {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
