.mink-google-map {
  &__marker-label {
    text-shadow: 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff, 0px 0px 2px #fff !important;
    color: #990000 !important;
  }
  &__info-window {
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}