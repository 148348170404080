.mink-scroll-top-button {
  position: fixed;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -3rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  // background-color: $green;
  background-color: var(--partner-primary, $sanoa-light-blue);
  border-radius: 50%;
  color: $white;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 30%);
  user-select: none;
  cursor: pointer;
  transition: bottom 0.25s;

  &::after {
    content: "";
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: $icon-chevron-up;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @include media-breakpoint-up(lg) {
    right: 1.5rem;
  }

  &.is-visible {
    bottom: 8rem;

    @include media-breakpoint-up(lg) {
      bottom: 8rem;
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-scroll-top-button {
    background-color: map-get($theme-colors, "sanoa-green-portal");
    &.is-visible {
      bottom: 4rem;
    }
  }
}
