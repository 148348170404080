.ida-header {
  margin-bottom: 1rem;
  width: 100%;
  position: relative;

  .img-container {
    @include aspect-ratio(3, 2);
    overflow: hidden;
    margin-left: -$contaner-mobile-padding;
    margin-right: -$contaner-mobile-padding;
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1.5rem;
      border-radius: $border-radius;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__shape {
    background-color: var(--brand-primary, var(--partner-primary));
    max-width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
      margin-right: $contaner-mobile-padding;
      padding: 0.5rem 0rem;
      margin-bottom: 0;
      &:not(.menu-is-open) {
        border-bottom-right-radius: 27px;
      }
    }
  }
  &__content {
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  &__menu-button {
    position: relative;
    box-sizing: content-box;
    flex: 0 0 auto;
    align-self: center;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    border: 0.125rem solid $white;
    border-radius: 50%;
    user-select: none;
    cursor: pointer;

    span {
      position: absolute;
      width: 1rem;
      height: 0.125rem;
      left: 0.5rem;
      background-color: $white;
      transform: rotate(0deg);
      transition: all 0.25s;

      &:nth-of-type(1) {
        top: calc(0.75rem - 0.0625rem - 0.0625rem);
      }
      &:nth-of-type(2) {
        top: calc(1rem - 0.0625rem);
      }
      &:nth-of-type(3) {
        top: calc(1rem - 0.0625rem);
      }
      &:nth-of-type(4) {
        top: calc(1.25rem);
      }
    }

    &.is-open span {
      &:nth-of-type(1) {
        left: 1rem;
        top: calc(1rem - 0.0625rem);
        width: 0;
      }
      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
      &:nth-of-type(3) {
        transform: rotate(45deg);
      }
      &:nth-of-type(4) {
        left: 1rem;
        top: calc(1rem - 0.0625rem);
        width: 0;
      }
    }
  }
  &__menu {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 1rem 0;
    background-color: var(--brand-primary, var(--partner-primary));
    color: $white;
    @include media-breakpoint-up(lg) {
      margin-right: $contaner-mobile-padding;
      border-bottom-right-radius: 1.688rem;
      margin-bottom: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 7px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
    }

    .social-icons {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 1.75rem;
      line-height: 1;
    }
    .social-link {
      display: inline-flex;
      padding: 6px;
    }
    .main-menu {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .sub-menu {
      list-style: none;
      padding: 0.5rem 1rem 0 1rem;
    }
    .nav_item {
      margin: 0;
      padding: 0 0 0.5rem 0;

      &.current-menu-item {
        pointer-events: none;
        opacity: 0.5;
      }
      .btn-link {
        color: $white;
        font-weight: 600;
        text-align: left;

        &::after {
          content: "";
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          margin-left: 0.75rem;
          margin-bottom: 0.35rem;
          vertical-align: bottom;
          background-image: $icon-chevron-up;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          transition: transform 0.125s;
        }
        &.open::after {
          transform: rotate(0);
        }
      }
      &::before {
        content: none;
      }
    }

    a,
    .main-menu-link {
      color: $white;
    }
  }
  h1 {
    //@extend .container-padding;
    padding-left: 0.7rem;
    color: $white;
    padding-top: 0.5rem;
    @include media-breakpoint-up(xxl) {
      margin-left: 0;
    }
  }
  &__address {
    display: block;
    font-size: 1rem;
    font-weight: normal;
    margin: 0.125rem 0;
  }
  &__logo {
    img {
      width: 7.5rem;
      height: 3.75rem;
      object-fit: contain;
      object-position: top right;
    }
  }
  &__logo-box {
    position: absolute;
    right: $contaner-mobile-padding;
    top: $contaner-mobile-padding;
  }
  &__mobile-box {
    position: absolute;
    right: $contaner-mobile-padding;
    bottom: 1rem;
    padding: 0.1rem 0.2rem 0.1rem 0.5rem;
    background-color: $white;
    border-radius: 1rem;
    line-height: 1;
    vertical-align: middle;
    .-phone {
      // padding: 0 1.25rem 0 0;
      color: $green;
      font-weight: $semi-bold;
      font-size: 0.75rem;
      &::after {
        content: "";
        background: $icon-phone-white no-repeat center/contain;
        display: inline-block;
        vertical-align: middle;
        height: 1.25rem;
        width: 1.25rem;
        margin-left: 0.5rem;
      }
    }
  }
  &__map {
    height: 240px;
    margin-left: -$contaner-mobile-padding;
    margin-right: -$contaner-mobile-padding;
    @include media-breakpoint-up(md) {
      height: 355px;
    }
    @include media-breakpoint-up(lg) {
      height: 240px;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      border-radius: $border-radius;
    }
  }
  &__partner-map {
    height: 340px;
    margin-left: -$contaner-mobile-padding;
    margin-right: -$contaner-mobile-padding;
    @include media-breakpoint-up(md) {
      height: 355px;
    }
    @include media-breakpoint-up(lg) {
      height: 340px;
      margin-left: 0;
      margin-right: 0;
      overflow: hidden;
      border-radius: $border-radius;
    }
  }
  &__catchment-area {
    padding: 0 0.7rem;
    font-size: 0.8rem;
  }
}
