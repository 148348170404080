// ----------------------------------------------------------------
// ACF Text Editor: WYSIWYG editor; build in Wordpress tinymce
// ----------------------------------------------------------------
.acf-text-editor {
  // WordPress Generated Classes
  // http://codex.wordpress.org/CSS#WordPress_Generated_Classes
  blockquote {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    font-style: italic;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
    @include custom_colors($prop: color, $color: primary);
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 0.063rem solid var(--partner-primary);
  }

  // mobile: all images are 100%
  // images without caption are not covered in figure element
  .alignnone,
  .aligncenter,
  .alignleft,
  .alignright {
    width: 100%;
    height: auto;
    margin-bottom: 0;
    &.rounded-shadow {
      border-radius: 0.5rem;
      box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
    }
  }
  // if img has a align class, image is aligned in paragraph element
  // this moves the img over left padding
  img {
    &.alignnone,
    &.aligncenter,
    &.alignleft,
    &.alignright {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }

  // small screen: all images and captions are centered
  @include media-breakpoint-up(sm) {
    // images without caption are not covered in figure element,
    img {
      display: block;
      &.alignnone,
      &.aligncenter,
      &.alignleft,
      &.alignright {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }

  // large screens: images and captions are aligned
  @include media-breakpoint-up(lg) {
    img {
      &.alignnone,
      &.aligncenter,
      &.alignleft,
      &.alignright {
        width: fit-content;
        margin-right: auto;
        margin-left: 0;
      }
      &.aligncenter {
        margin: 0 auto;
      }
      &.alignright {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  // images with caption are covered in figure element
  .wp-caption {
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0.7rem;
    padding: 0;
    width: calc(100% - 0.7rem) !important;
    height: auto;
    &.alignnone,
    &.aligncenter,
    &.alignleft,
    &.alignright {
      a {
        display: flex;
        flex-direction: column;
      }
      img {
        width: 100%;
        height: auto;
        align-self: flex-start;
      }
      figcaption {
        font-size: 0.8rem;
        text-align: left;
        a {
          display: inline-block;
        }
      }
      .rounded-shadow {
        border-radius: 0.5rem;
        box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
      }
    }
    @include media-breakpoint-up(sm) {
      &.alignnone,
      &.aligncenter,
      &.alignleft,
      &.alignright {
        img {
          width: fit-content;
          max-width: 100%;
          align-self: center;
        }
        figcaption {
          text-align: center;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &.alignnone,
      &.aligncenter,
      &.alignleft,
      &.alignright {
        img {
          align-self: flex-start;
        }
        figcaption {
          text-align: left;
        }
      }
      &.aligncenter {
        img {
          align-self: center;
        }
        figcaption {
          text-align: center;
        }
      }
      &.alignright {
        img {
          align-self: flex-end;
        }
        figcaption {
          text-align: right;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4 {
    padding: 0 0 0 0.7rem;
    margin-bottom: 1rem;
    color: var(--partner-primary);
  }
  p {
    padding: 0 0 1.5rem 0.7rem;
    margin: 0;
  }
  ul {
    padding: 0 0 1.5rem 2.5rem;
    li {
      &::before {
        color: var(--partner-primary);
      }
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .acf-text-editor {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
    }
    blockquote {
      font-size: 1.25rem;
      margin-bottom: 0.8rem;
      font-weight: 900;
      font-style: italic;
      color: map-get($theme-colors, "sanoa-green-portal");
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
    .wp-caption {
      display: flex;
      flex-direction: column;
      margin: 0 0 2rem;
      padding: 0;
      width: 100%;
      height: auto;
      &.alignnone,
      &.aligncenter,
      &.alignleft,
      &.alignright {
        a {
          display: flex;
          flex-direction: column;
        }
        img {
          width: 100%;
          height: auto;
          align-self: flex-start;
        }
        figcaption {
          font-size: 0.8rem;
          text-align: left;
          a {
            display: inline-block;
          }
        }
        .rounded-shadow {
          border-radius: 0.5rem;
          box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
        }
      }
      @include media-breakpoint-up(sm) {
        &.alignnone,
        &.aligncenter,
        &.alignleft,
        &.alignright {
          img {
            width: fit-content;
            max-width: 100%;
            align-self: center;
          }
          figcaption {
            text-align: center;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        width: 66% !important;
        &.alignnone,
        &.aligncenter,
        &.alignleft,
        &.alignright {
          img {
            align-self: flex-start;
          }
          figcaption {
            text-align: left;
            // width: 66%;
          }
        }
        &.aligncenter {
          img {
            align-self: center;
          }
          figcaption {
            text-align: center;
          }
        }
        &.alignright {
          img {
            align-self: flex-end;
          }
          figcaption {
            text-align: right;
            // width: 66%;
          }
        }
      }
    }

    p {
      padding: 0 0 1.5rem 0;
      margin: 0;
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
      &:has(.w-100) {
        width: 100%;
      }
    }
    a {
      position: relative;
      font-weight: 600;
      @extend .sn-link;
    }
    ul {
      li {
        list-style-type: disc;
        &::before {
          // color: var(--partner-primary);
          display: none;
        }
      }
    }
    ul,
    ol {
      margin-bottom: 0;
      padding: 0 0 1.5rem 2rem;
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
      li {
        &:last-child {
          margin: 0;
        }
      }
    }
    hr {
      border-top: 0.125rem solid map-get($theme-colors, "sanoa-primary");
      margin: 1rem 0 1.5rem 0;
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
    }
    table {
      @extend .table;
      margin-bottom: 1.5rem;
      @include media-breakpoint-up(lg) {
        width: 66%;
      }
      td {
        border-top: 0.125rem solid map-get($theme-colors, "sanoa-primary");
        color: map-get($theme-colors, "sanoa-primary");
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol,
        hr {
          width: auto;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
