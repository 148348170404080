// ----------------------------------------------------------------
// ACF review locations, shows all location of the current partner
// with review link
// ----------------------------------------------------------------
.acf-review-location-list {
  display: flex;
  flex-direction: column;
  margin: 0 0 1.5rem 0;
  @include media-breakpoint-up(md) {
    margin: 0 0 2rem 0;
    flex-direction: row;
  }
  &__img {
    width: 100%;
    border: transparent;
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      width: 22.5rem;
    }
    @include media-breakpoint-up(md) {
      width: 25rem;
      height: 10rem;
      border-radius: $border-radius 0 0 $border-radius;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__text {
    width: 100%;
    padding: 1.5rem 0 0 0;
    @include media-breakpoint-up(md) {
      padding: 0 0 0 2rem;
    }
  }
  &__button {
    @extend .mink-cta-button;
    margin: 2rem 0 1rem 0;
    @include media-breakpoint-up(md) {
      width: fit-content;
      margin: 2rem 0 0 0;
    }
  }
}
// ----------------------------------------------------------------
// temorarily embed via popup
// ----------------------------------------------------------------
.single-page {
  &.post {
    .acf-review-location-list {
      &__img {
        @include media-breakpoint-up(xl) {
          width: 30rem;
          height: 15rem;
        }
      }
    }
  }
}

// ----------------------------------------------------------------
// embeded without react app
// ----------------------------------------------------------------

.page-template-template-review {
  .acf-review-location-list {
    &__img {
      @include media-breakpoint-up(xl) {
        width: 30rem;
        height: 15rem;
      }
    }
  }
}
