.mink-footer {
  padding: 0.5rem;
  position: fixed;
  z-index: 99;
  left: 0;
  right: $contaner-mobile-padding;
  bottom: 0;
  @include media-breakpoint-up(md) {
    left: auto;
    width: 100%;
    max-width: 35rem;
    padding: 0;
  }
  &__item {
    pointer-events: auto;
    margin: 0 0.5rem 0 0;
    &:last-child {
      margin: 0;
    }
  }
}

.mink-footer-nav {
  &__menu {
    .main-menu {
      padding: 0;
      display: flex;
      margin-bottom: 0;
    }
    .nav_item {
      display: inline;
      padding: 0;
      a {
        color: $body-color;
      }
      &:not(:first-child):before {
        content: "|";
        padding: 0 0.2rem;
        color: $body-color;
        display: inline;
        left: 0;
        font-size: initial;
        position: static;
      }
      &:first-child:before {
        content: none;
      }
    }
  }
}
