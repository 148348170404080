// ----------------------------------------------------------------
// swiper slider on desktops with large teasers
// ----------------------------------------------------------------
.mink-slider-lg {
  position: relative;
  cursor: pointer;
  padding-left: 0.4rem;
  @include media-breakpoint-up(sm) {
    left: $box-shadow-width * -1;
    width: calc(100% + $box-shadow-width);
  }
  &__item {
    display: flex;
    height: auto;
    width: calc(45% + $box-shadow-width);
    background-color: $white;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(sm) {
      width: auto;
      margin-bottom: 2rem;
    }
  }
  &__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    cursor: pointer;
  }
  .-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .-wrapper {
    flex: 0 0 auto;
    @include aspect-ratio(4, 3);
    overflow: hidden;
    object-fit: cover;
    iframe {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    text-align: center;
  }
  .-text-wrapper {
    flex: 1 1 auto;
    padding: 0.5rem 0.75rem 0 0.75rem;
    background-color: $white;
  }
  .-head {
    font-weight: 600;
    color: $primary;
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  .-head-no-link {
    font-weight: 600;
    color: $primary;
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  .-text {
    color: $body-color;
    width: 100%;
    text-overflow: ellipsis;
    hyphens: auto;
    overflow: hidden;
    ul {
      padding: 0rem 0 0.5rem 1.2rem;
    }
    a {
      color: $body-color;
      text-decoration: underline;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .-link-wrapper {
    flex: 0 0 auto;
    height: 1.7rem;
    margin: 1.4rem 1rem 0.5rem 1rem;
  }
  .-link {
    font-weight: 600;
    text-align: right;
    a {
      @extend .-icon-arrow-right;
    }
    @include media-breakpoint-up(sm) {
      padding: 0.2rem 0;
      border-radius: 0.2rem;
      text-align: center;
      background-color: var(--brand-primary, var(--partner-primary));
      a {
        color: $white;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
}

// ----------------------------------------------------------------
// static slide container if not enough slides
// ----------------------------------------------------------------
.mink-slider-lg-inactive {
  display: flex;
  overflow: hidden;
  gap: 16px;
  margin-left: 5px;
  cursor: auto;
}

// ----------------------------------------------------------------
// slider with one slide or slider on mobile device < lg
// ----------------------------------------------------------------
.mink-teaser-lg {
  @extend .mink-slider-lg;
  left: -0.4rem;
  margin: 0 0.5rem 1.5rem 0;
  @include media-breakpoint-up(sm) {
    width: 50%;
    margin: 0 1rem 2rem 0;
  }
  .-text-wrapper {
    height: auto;
  }
  .-link-wrapper {
    height: auto;
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-slider-lg {
    &__item-link {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      z-index: 5;
      cursor: pointer;
    }
    .-img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      text-align: center;
    }
    .-head {
      font-weight: 700;
      color: map-get($theme-colors, "sanoa-primary");
      font-size: 1rem;
      margin-bottom: 0.3rem;
      a {
        color: map-get($theme-colors, "sanoa-primary");
        &:hover {
          text-decoration: none;
        }
      }
    }
    .-head-no-link {
      font-weight: 700;
      color: map-get($theme-colors, "sanoa-primary");
      font-size: 1rem;
      margin-bottom: 0.3rem;
    }
    .-text {
      color: $body-color;
      width: 100%;
      text-overflow: ellipsis;
      hyphens: auto;
      overflow: hidden;
      ul {
        padding: 0rem 0 0.5rem 1.2rem;
      }
      a {
        color: $body-color;
        text-decoration: underline;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
    .-link-wrapper {
      flex: 0 0 auto;
      height: 1.7rem;
      margin: 1.4rem 1rem 0.5rem 1rem;
    }
    .-link {
      font-weight: 700;
      text-align: right;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      a {
        @extend .sn-link;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
}
