.acf-locator {
  &__layover {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    z-index: 20;
    box-shadow: $box-shadow;
    background-color: $white;
    font-family: $font-portal;
    font-size: 0.938rem;
    overflow: auto;
    padding: 1rem 1rem 2rem 1rem;
    @include media-breakpoint-up(md) {
      top: 0.5rem;
      left: 0.5rem;
      right: auto;
      height: 33rem;
      width: 30rem;
      font-size: 1rem;
    }
    .-filter & {
      @include media-breakpoint-up(md) {
        bottom: 5rem;
      }
    }
  }

  .location-thumb {
    display: flex;
    align-items: flex-start;
    padding: 0 2.5rem 0.5rem 0;
    @include media-breakpoint-up(md) {
      padding: 0 0 0.5rem 0;
    }
    .img-container {
      height: 172px;
      width: 258px;
      display: block;
      @include aspect-ratio(3, 2);
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .location-detail-head,
  .location-brand-head,
  .location-service-head {
    font-size: 0.9375rem;
    font-weight: 900;
    @include media-breakpoint-up(md) {
      font-size: 1.0625rem;
    }
    a {
      color: inherit;
      &:hover {
        color: inherit;
        text-decoration: none !important;
      }
    }
  }
  .location-detail {
    @include media-breakpoint-up(md) {
      // width: 66%;
      // &.non-partner {
      //   width: 100%;
      // }
    }
    &-head {
      padding-right: 1rem;
    }
    p {
      margin-bottom: 0.25rem;
    }
    .location-address,
    .location-phone {
      margin-bottom: 0.25rem;
      a {
        color: inherit;
        &:hover {
          color: inherit;
          text-decoration: none;
        }
      }
    }
    .location-opening-hours {
      color: inherit;
      margin-bottom: 0.25rem;
      &.current-day {
        color: $green;
      }
    }
    .location-service-head {
      margin: 1rem 0 0.5rem 0;
    }
    .location-services {
      height: 3rem;
      overflow: hidden;
      display: flex;
      &.-active {
        height: auto;
        overflow: auto;
      }
      &__toggle {
        position: relative;
        margin: 0;
        padding: 0 0 0 0;
        font-weight: 600;
        cursor: pointer;
        .-less {
          position: relative;
          display: none;
          &::before {
            content: ">";
            position: absolute;
            top: 0.3rem;
            left: 8.8rem;
            width: 0.8rem;
            height: 0.8rem;
            display: inline-block;
            transform: rotate(-90deg);
            font-size: 1rem;
          }
        }
        .-more {
          position: relative;
          &::before {
            content: ">";
            position: absolute;
            top: 0.4rem;
            left: 8.2rem;
            width: 0.8rem;
            height: 0.8rem;
            display: inline-block;
            transform: rotate(90deg);
            font-size: 1rem;
          }
        }
        &.open {
          .-more {
            display: none;
          }
          .-less {
            display: block;
          }
        }
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
    .location-services-list {
      display: flex;
      flex-wrap: wrap;
      .location-service {
        // margin: 0 0.5rem 1rem 0;
        a {
          padding: 0.375rem 0.5rem;
          min-width: 6.25rem;
          text-wrap: nowrap;
        }
        &:not(:last-of-type)::after {
          content:",\00a0";
        }
      }
    }

    .location-brand-head {
      margin: 1rem 0 0.5rem 0;
    }
    .location-brand-logos {
      margin-bottom: 1rem;
      max-width: 20rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .location-brand-logo {
        padding: 0 0.5rem 0.5rem 0;
        max-width: 5rem;
        a {
          display: flex;
          align-items: center;
          height: 100%;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .location-brand-list {
      display: flex;
      flex-wrap: wrap;
      .location-brand-link {
        margin: 0 0.5rem 1rem 0;
        a {
          padding: 0.375rem 0.5rem;
          min-width: 6.25rem;
          text-wrap: nowrap;
        }
      }
    }
    .location-button {
      margin-top: 1rem;
    }
  }
  .layover-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M14.209 14.207l-13.5 13.5 13.5-13.5L.709.707l13.5 13.5 13.5-13.5-13.5 13.5 13.5 13.5z' style='mix-blend-mode:multiply;isolation:isolate' fill='%234a4a4a' stroke='%234a4a4a' stroke-width='2'/%3E%3C/svg%3E");
    background-size: contain;
    text-indent: -9999em;
  }
}

.single-sanoa_embed {
  .acf-locator {
    &__layover {
      max-height: 516px;
      overflow-y: auto;
      height: 96vh;
      @include media-breakpoint-up(md) {
        max-height: 720px;
      }
    }
  }
}
