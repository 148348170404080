/* paths */
//$font-path: "/content/themes/sanoa/assets/fonts/";
//$image-path: "/content/themes/sanoa/assets/images/";
$font-path: "./../../../public/content/themes/sanoa/assets/fonts/";
$image-path: "./../../../public/content/themes/sanoa/assets/images/";
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4; // $grid-gutter-width: 60px;
/* grayscale settings */
$white: #fff !default;
$gray-100: #f5f5f5; // footer bg
$gray-200: #e9ecef !default;
$gray-250: #e7eaea; // header bg
$gray-300: #d8d8d8; // linien
$gray-400: #c3c3c3; // main bg
$gray-500: #979797; // rahmen
$gray-600: #6f6f6f !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2f2f2f; // font color
$grays: (
  "250": $gray-250,
);
$black: #000 !default;
/* color settings */
$gray-dark: #6f6f6f;
$blue: #3679eb; // theme primary
$blue-light: #3ea1e5;

$sanoa-blue: #19487e;
$sanoa-light-blue: #f5feff;
$sanoa-primary: #19487e;
$sanoa-secondary: #f5feff;
$sanoa-orange: #fd9122;
// $sanoa-button: #458606;
$sanoa-button: #3ab46c;

$indigo: #102e6c; // dunkles blau Orthopädietechnik
$purple: #6f42c1 !default;
$pink: #e9526b; // Kliniken
$red: #dc3545 !default;
$orange: #fd9b32; //Physiotherapeuten
$yellow: #efa400; //gelb
$green: #3ab46c; // grün
$green-dark: #1c653a;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue;
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$cta-color: $green;
$cta-dark-color: $green-dark;
$btn-shadow: 0 0 0.5rem rgba($black, 0.5);

$sanoa-color: $blue-light;
$sanoa-green-portal: #458606;
/* fallback if partner is not set not*/
$partner-primary: #6f6f6f;
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "cta-color": $cta-color,
    "cta-dark-color": $cta-dark-color,
    "sanoa-color": $sanoa-color,
    "partner-primary": $partner-primary,
    "sanoa-blue": $sanoa-blue,
    "sanoa-green": $green,
    "sanoa-primary": $sanoa-primary,
    "sanoa-secondary": $sanoa-secondary,
    "sanoa-orange": $sanoa-orange,
    "sanoa-button": $sanoa-button,
    "sanoa-green-portal": $sanoa-green-portal,
  ),
  $theme-colors
);
$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

/* grid */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  xl: 1360px,
  xxl: 1860px,
);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1360px,
  xxl: 1920px,
  xxxl: 3400px,
);

/* common */
$container-margin: 0 0 1.5rem 0;
$contaner-mobile-padding: 7px;
// this is the boostarp variable
$grid-gutter-width: $contaner-mobile-padding * 2;
$contaner-padding: 15px;
$secondary: $green;
$hover: $white;
$active: $white;
$body-bg: $gray-100;
$body-color: $gray-900;
$font: "mink-work-sans", sans-serif;
$font-portal: "mink-montserrat", sans-serif;
$border: 2px solid #ffffff;
$border-small: 1px solid #d8d8d8;
$border-menu: 1px solid #b0b0b0;
$border-header: 2px solid #b0b0b0dc;
$border-dotted: 0.2rem dotted #999999;

$navbar-light-color: $gray-800;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

$icon-arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%233679EB' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 .001 17.001A8.5 8.5 0 0 0 8.5 0z'/%3E%3Cpath fill='%23FFF' d='m7.854 4.146 3.494 3.494a.503.503 0 0 1 .106.159.498.498 0 0 1-.092.579l-3.494 3.494a.5.5 0 0 1-.707-.707l3.148-3.149-3.163-3.162a.502.502 0 0 1 .708-.708z'/%3E%3C/g%3E%3C/svg%3E");
$icon-arrow-right-blue: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%233679EB' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z'/%3E%3Cpath fill='%23FFF' d='m13.173 8.632-7 3.5v-7z'/%3E%3C/g%3E%3C/svg%3E");
$icon-arrow-right-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 .001 17.001A8.5 8.5 0 0 0 8.5 0z'/%3E%3Cpath fill='%233AB46C' d='m13.173 8.632-7 3.5v-7z'/%3E%3C/g%3E%3C/svg%3E");
$icon-chevron-up: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='15px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Footer-Up-Arrow' transform='translate(-10.000000  -12.000000)' stroke='%23FFFFFF' stroke-width='3'%3E%3Cpolyline id='Path-4' points='12 25.5124406 22.3643725 13.5124406 32 25.5124406'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$icon-chevron-up-blue: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='15px' viewBox='0 0 24 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Footer-Up-Arrow' transform='translate(-10.000000  -12.000000)' stroke='%2319487e' stroke-width='3'%3E%3Cpolyline id='Path-4' points='12 25.5124406 22.3643725 13.5124406 32 25.5124406'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$icon-map-marker: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='18'%3E%3Cpath fill='%2300AEEF' fill-rule='evenodd' d='M13 6.235C13 2.792 10.09 0 6.5 0S0 2.792 0 6.235c0 .106.003.21.008.314A6.358 6.358 0 0 0 0 6.862C0 10.306 6.5 18 6.5 18S13 10.306 13 6.862c0-.105-.003-.21-.008-.313.005-.104.008-.208.008-.314ZM6.5 9.398c-1.22 0-2.32-.705-2.786-1.786a2.808 2.808 0 0 1 .653-3.153 3.11 3.11 0 0 1 3.288-.627c1.127.448 1.861 1.503 1.861 2.673 0 1.598-1.35 2.893-3.016 2.893Z' class='fill-partner-color'/%3E%3C/svg%3E");
$icon-check: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 .001 17.001A8.5 8.5 0 0 0 8.5 0z'/%3E%3Cpath d='M0 0h14v14H0z'/%3E%3Cpath fill='%233AB46C' fill-rule='nonzero' d='M14.036 4.199a.75.75 0 0 1 .112.973l-.07.087-7.555 8.182a.75.75 0 0 1-.804.197.752.752 0 0 1-.747-.198l-.068-.082-2.76-3.802-.06-.094a.75.75 0 0 1 1.201-.872l.072.085 2.432 3.349 7.188-7.783a.75.75 0 0 1 1.06-.042z'/%3E%3C/g%3E%3C/svg%3E");
$icon-collapse-open: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%233679EB' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z'/%3E%3Crect width='1' height='9' x='8' y='4' fill='%23FFF' rx='.5'/%3E%3Crect width='1' height='9' x='8' y='4' fill='%23FFF' rx='.5' transform='rotate(90 8.5 8.5)'/%3E%3C/g%3E%3C/svg%3E");
$icon-collapse-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%233679EB' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z'/%3E%3Crect width='1' height='9' x='8' y='4' fill='%23FFF' rx='.5' transform='rotate(90 8.5 8.5)'/%3E%3C/g%3E%3C/svg%3E");
$icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' data-name='Ebene 2'%3E%3Ccircle cx='16' cy='16' r='12.5' style='fill:%23fff'/%3E%3Cpath d='m17.458 15.954 6.123-6.135a1 1 0 0 0-1.416-1.413l-6.12 6.134-6.131-6.134A1 1 0 0 0 8.499 9.82l6.132 6.136-6.211 6.225a1 1 0 1 0 1.416 1.412l6.21-6.223 6.12 6.124a1 1 0 0 0 1.414-1.414Z' style='fill:%233ab46c'/%3E%3C/svg%3E");
$icon-phone: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='55'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(0 .988)'%3E%3Ccircle cx='27' cy='27' r='27' fill='%23FFF' fill-rule='nonzero'/%3E%3Cpath fill='%231C653A' d='M16.843 13.994c4.72-3.313 5.694-2.479 7.208 2.359 1.152 3.68.495 4.141-2.33 6.124.943 4.699 4.512 9.809 8.593 12.304 2.825-1.983 3.482-2.444 6.537-.1 4.016 3.081 4.465 4.284-.255 7.597-7.932 5.568-27.685-22.715-19.753-28.284z'/%3E%3C/g%3E%3C/svg%3E");
$icon-phone-white: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='54' height='54'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(0 -.012)'%3E%3Ccircle cx='27' cy='27' r='27' fill='%233AB46C' fill-rule='nonzero'/%3E%3Cpath fill='%23FFF' d='M16.843 13.994c4.72-3.313 5.694-2.479 7.208 2.359 1.152 3.68.495 4.141-2.33 6.124.943 4.699 4.512 9.809 8.593 12.304 2.825-1.983 3.482-2.444 6.537-.1 4.016 3.081 4.465 4.284-.255 7.597-7.932 5.568-27.685-22.715-19.753-28.284Z'/%3E%3C/g%3E%3C/svg%3E");
$icon-email: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='38'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(0 .988)'%3E%3Ccircle cx='18.5' cy='18.5' r='18.5' fill='%23FFF' fill-rule='nonzero'/%3E%3Cpath fill='%231C653A' d='m16.554 20.415.092.07a3.162 3.162 0 0 0 3.708.001l.08-.06 4.71 4.695 1.325 1.32c-.507.33-1.094.52-1.74.52H12.27c-.645 0-1.232-.19-1.727-.52l1.313-1.32 4.698-4.706zM28 14.861v8.75c0 .555-.127 1.074-.357 1.522l-1.382-1.38-4.399-4.387 4.41-3.244L28 14.861zm-19-.012 1.727 1.273 4.399 3.244-4.387 4.387-1.382 1.38A3.326 3.326 0 0 1 9 23.61v-8.76zm15.73-3.81c1.29 0 2.395.755 2.936 1.864l-1.45 1.073-6.875 5.071a1.38 1.38 0 0 1-.841.271c-.3 0-.587-.082-.84-.27l-6.875-5.072-1.451-1.061c.53-1.109 1.647-1.876 2.936-1.876h12.46z'/%3E%3C/g%3E%3C/svg%3E");

$icon-menu-toggler: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2319487e' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$icon-menu-toggler-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%2319487e;stroke:%2319487e;stroke-width:2px;mix-blend-mode:multiply;isolation:isolate;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M20566.5,23758.5l-13.5,13.5,13.5-13.5-13.5-13.5,13.5,13.5,13.5-13.5-13.5,13.5,13.5,13.5Z' transform='translate(-20552.291 -23744.293)'/%3E%3C/svg%3E");
$icon-arrow-right-sanoa-primary: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%2319487e' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z'/%3E%3Cpath fill='%23FFF' d='m13.173 8.632-7 3.5v-7z'/%3E%3C/g%3E%3C/svg%3E");
$icon-arrow-right-sanoa-green: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23458606' fill-rule='nonzero' d='M8.5 0a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Z'/%3E%3Cpath fill='%23FFF' d='m13.173 8.632-7 3.5v-7z'/%3E%3C/g%3E%3C/svg%3E");
$icon-map: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='219' height='162'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(0 -7)'%3E%3Crect width='205' height='106' x='6.5' y='56.5' stroke='%2319487E' stroke-width='13' rx='23'/%3E%3Cg transform='translate(94)'%3E%3Cellipse cx='46.5' cy='46.71' fill='%23FFF' rx='46.5' ry='46.71'/%3E%3Cpath fill='%2319487E' stroke='%23FFF' d='M46.5 8.285a38.243 38.243 0 0 1 27.048 11.139C80.469 26.304 84.75 35.81 84.75 46.31a40.117 40.117 0 0 1-.048 1.91c.032.684.048 1.321.048 1.962 0 19.941-34.215 63.013-38.25 68.02-4.042-5.018-38.249-48.081-38.25-68.018.001-.641.017-1.279.048-1.913a41.264 41.264 0 0 1-.048-1.96c0-10.501 4.281-20.007 11.202-26.888A38.243 38.243 0 0 1 46.5 8.285Zm5.421 21.43a18.104 18.104 0 0 0-10.61-1.06 18.26 18.26 0 0 0-9.4 5.095 18.676 18.676 0 0 0-5.022 9.53 18.835 18.835 0 0 0 1.043 10.75 18.54 18.54 0 0 0 6.76 8.354 18.16 18.16 0 0 0 10.201 3.139c5.07 0 9.66-2.084 12.982-5.454a18.686 18.686 0 0 0 5.375-13.158c0-3.763-1.117-7.34-3.091-10.338a18.396 18.396 0 0 0-8.238-6.858Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
$icon-list: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='219' height='162'%3E%3Cg fill='%2319487E' fill-rule='evenodd' transform='translate(0 9)'%3E%3Crect width='167' height='14' x='52' y='124' rx='6'/%3E%3Crect width='167' height='14' x='52' y='67' rx='6'/%3E%3Crect width='167' height='14' x='52' y='10' rx='6'/%3E%3Crect width='37' height='34' rx='8'/%3E%3Crect width='37' height='34' y='114' rx='8'/%3E%3Crect width='37' height='34' y='57' rx='8'/%3E%3C/g%3E%3C/svg%3E");
$icon-third-party: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='128px' height='128px' viewBox='0 0 128 128' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M41.6064896,35 L92,35 C98.627417,35 104,40.372583 104,47 L104,70.885478 C104,77.512895 98.627417,82.885478 92,82.885478 L65.3990973,82.885478 L65.3990973,82.885478 L48.1965166,98 L48.1965166,82.885478 L41.6064896,82.885478 C34.9790726,82.885478 29.6064896,77.512895 29.6064896,70.885478 L29.6064896,47 C29.6064896,40.372583 34.9790726,35 41.6064896,35 Z' id='Rectangle' fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

$warning: $active;

$border-width: 0.188rem;
$border-color: $black;
$border-radius: 0.5rem;
$border-button-radius: 25px;

$box-shadow-width: 0.7rem;
$box-shadow: 0 0 $box-shadow-width rgba($black, 0.15);

$semi-bold: 600;
// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.5rem;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  $white !default;
// $modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
// $modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
// $modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: $indigo !default;
$modal-backdrop-opacity: 0.5 !default;
// $modal-header-border-color:         $border-color !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-footer-border-width:         $modal-header-border-width !default;
// $modal-header-padding-y:            1rem !default;
// $modal-header-padding-x:            1rem !default;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-xl:                          1140px !default;
// $modal-lg:                          800px !default;
// $modal-md:                          500px !default;
// $modal-sm:                          300px !default;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;
