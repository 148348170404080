// ----------------------------------------------------------------
// the popup posts, especially for legal and review
// ----------------------------------------------------------------
.mink-popup {
  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 5rem;
    background-color: $white;
    box-shadow: $box-shadow;
  }
}
