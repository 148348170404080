// Slider
@at-root {
  :root {
    --swiper-theme-color: $black;
    --swiper-navigation-size: 44px;
    --swiper-navigation-color: $black;
  }
}

.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    @extend .d-none, .d-sm-flex;
    width: var(--swiper-navigation-size);
    height: var(--swiper-navigation-size);
    box-shadow: $box-shadow;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.75);
    &:after {
      font-size: 26px !important;
    }
    &.swiper-button-disabled {
      @extend .d-none;
    }
  }
}
