// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

.wp-block-image {
  width: 100% !important;
  padding-top: 0.4rem;
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  figcaption {
    font-size: 0.8rem;
    text-align: right;
  }
}

.wp-block-embed {
  width: 100%;
  margin: 0 0 0 0;
  &__wrapper {
    @include aspect-ratio(16, 9);
    iframe {
      width: 100%;
      height: 100%;
    }
    .BorlabsCookie {
      height: 100%;
    }
  }
  figcaption {
    position: absolute;
    padding-top: 0.5rem;
    font-size: 0.8rem;
  }
}

.wp-block-columns {
  @extend .row;
  .ida-wp-block-column {
    @extend .col;
    flex-basis: 100%;
    padding-right: calc(#{$contaner-mobile-padding} + 0.7rem);
    & > * {
      padding: 0 0 0 0.7rem;
    }
    @include media-breakpoint-up(md) {
      flex-basis: 0;
      padding: 0 0 0 0.7rem;
      &:last-child {
        padding-right: calc(#{$contaner-mobile-padding} + 0.7rem);
      }
    }
  }
  &.has-2-columns {
    .ida-wp-block-column {
      @extend .col-12;
      @extend .col-lg-6;
    }
  }
  &.has-3-columns {
    .ida-wp-block-column {
      @extend .col-12;
      @extend .col-lg-4;
    }
  }
  &.has-4-columns {
    .ida-wp-block-column {
      @extend .col-12;
      @extend .col-lg-3;
    }
  }
}
// Booking-Time Embed iframe
.ida_booking_time_embed {
  min-height: 500px;
}

ul.wp-block-social-links,
ul.blocks-gallery-grid {
  padding-left: 0;
  > li {
    &::before {
      display: none;
    }
  }
}
