.ida-location-box {
  @include media-breakpoint-up(xl) {
    position: relative;
    z-index: 10;
    @media (min-height: 800px) {
      position: sticky;
      top: 0.5rem;
    }
  }
  margin: 0 0 2.5rem 0;
  &.contact-hidden {
    margin-bottom: 0;
  }
  &__sticky-container {
    // outside container with full page height for xl screens
    position: absolute;
    padding-top: 7rem; // space for ida-header__shape (title)
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .container {
      height: 100%;
      padding: 0;
    }
  }
  &__content {
    @include custom_colors($prop: border-color, $color: primary);
    padding: 1rem;
    .contact-hidden & {
      padding-bottom: 0;
    }
    overflow: hidden;
    h3,
    h4 {
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0;
    }
    .-headline {
      font-size: 1.125rem;
      @include custom_colors($prop: color, $color: primary);
      &:empty {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
      }
    }
    @include media-breakpoint-up(lg) {
      padding: 0.25rem 0.25rem 0.25rem 2rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 1.25rem;
      border-width: $border-width;
      border-style: solid;
      border-radius: $border-radius;
    }
  }
  &__contact {
    &:not(:empty) {
      margin-bottom: 1rem;
    }
    h4 {
      flex: 0 0 auto;
      margin: 0 0.25rem 0 0;
      line-height: 1.5;
    }
  }
  &__logo {
    // border: 0.063rem solid map-get( $grays , 'gray-500' );
    width: 7.375rem;
    height: 7.375rem;
    display: flex;
    align-items: center;
    margin: 0 1rem 1rem 0;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 8.75rem;
      height: 8.75rem;
    }
  }
  &__info {
    // margin: 1rem 0;
    .-hours {
      margin-bottom: 1rem;
      .current-day {
        @include custom_colors($prop: color, $color: primary);
      }
    }
  }
  .-cta {
    margin-top: 0.5rem;
    padding-left: 0.25rem;
    @include media-breakpoint-up(lg) {
      padding-left: 1rem;
    }
    gap: 0.5rem 0.25rem;
    text-align: center;
    .-button {
      flex: 0 0 auto;
      @extend .btn;
      color: #fff;
      font-weight: 600;
      @include custom_colors($prop: background-color, $color: primary);
      padding: 0.25rem 0.5rem;
      border-radius: 1.5rem;
    }
    // TODO: H3 obsolete??
    h3 {
      margin: 0;
      padding: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 1.5rem;
      padding-left: 2rem;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 0;
    }
  }
  h3,
  h4 {
    @extend h3;
    color: $gray-900;
  }
  &__header {
    margin-bottom: 1rem;
    .-title {
      font-weight: 600;
      a {
        @include custom_colors($prop: color, $color: primary);
      }
    }
    /*@include media-breakpoint-up(lg) {
            align-self: flex-end;
        }*/
    .-more-button {
      margin-top: 0.5rem;
      color: var(--partner-primary);
      user-select: none;
      cursor: pointer;
      &::after {
        content: "\25bc";
        display: inline-block;
        font-size: 0.6875rem;
        margin-left: 0.5rem;
      }
      &.is-open::after {
        transform: rotate(180deg);
        padding-top: 0.125rem;
      }
    }
  }
}
