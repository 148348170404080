//https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@use "sass:math";

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }

  > .aspect-content,
  > .entry-content-asset {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// get custom color vars mixin
// use @include colors($prop: border-color, $color: primary)
@mixin custom_colors($prop, $color) {
  #{$prop}: var(--partner-#{$color});
  @at-root .single-sanoa_brand #{&} {
    #{$prop}: var(--brand-#{$color});
  }
}
