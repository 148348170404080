/* work-sans-regular - latin */
/*
 * @see Roots\Sage\Setup::ida_preload_fonts()
 */
@font-face {
  font-family: "mink-work-sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("#{$font-path}worksans-regular-400.eot");
  src: local(""), url("#{$font-path}worksans-regular-400.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}worksans-regular-400.woff2") format("woff2"),
    url("#{$font-path}worksans-regular-400.woff") format("woff"),
    url("#{$font-path}worksans-regular-400.ttf") format("truetype"),
    url("#{$font-path}worksans-regular-400.svg#WorkSans") format("svg");
}
/* work-sans-600 - latin */
@font-face {
  font-family: "mink-work-sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("#{$font-path}worksans-semibold-600.eot");
  src: local(""), url("#{$font-path}worksans-semibold-600.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}worksans-semibold-600.woff2") format("woff2"),
    url("#{$font-path}worksans-semibold-600.woff") format("woff"),
    url("#{$font-path}worksans-semibold-600.ttf") format("truetype"),
    url("#{$font-path}worksans-semibold-600.svg#WorkSans") format("svg");
}
/* work-sans-800 - latin */
@font-face {
  font-family: "mink-work-sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("#{$font-path}worksans-extrabold-800.eot");
  src: local(""), url("#{$font-path}worksans-extrabold-800.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}worksans-extrabold-800.woff2") format("woff2"),
    url("#{$font-path}worksans-extrabold-800.woff") format("woff"),
    url("#{$font-path}worksans-extrabold-800.ttf") format("truetype"),
    url("#{$font-path}worksans-extrabold-800.svg#WorkSans") format("svg");
}
/* work-sans-300italic - latin */
@font-face {
  font-family: "mink-work-sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("#{$font-path}worksans-lightitalic-300.eot");
  src: local(""), url("#{$font-path}worksans-lightitalic-300.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}worksans-lightitalic-300.woff2") format("woff2"),
    url("#{$font-path}worksans-lightitalic-300.woff") format("woff"),
    url("#{$font-path}worksans-lightitalic-300.ttf") format("truetype"),
    url("#{$font-path}worksans-lightitalic-300.svg#WorkSans") format("svg");
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path}montserrat-v26-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("#{$font-path}montserrat-v26-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("#{$font-path}montserrat-v26-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("#{$font-path}montserrat-v26-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("#{$font-path}montserrat-v26-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("#{$font-path}montserrat-v26-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("#{$font-path}montserrat-v26-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("#{$font-path}montserrat-v26-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("#{$font-path}montserrat-v26-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 800;
  src: url("#{$font-path}montserrat-v26-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("#{$font-path}montserrat-v26-latin-900.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "mink-montserrat";
  font-style: italic;
  font-weight: 900;
  src: url("#{$font-path}montserrat-v26-latin-900italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
