// ----------------------------------------------------------------
// swiper slider on desktops with small teasers
// type generic and editorial
// ----------------------------------------------------------------
.mink-slider-sm {
  position: relative;
  cursor: pointer;
  left: $box-shadow-width * -1;
  width: calc(100% + $box-shadow-width);
  padding-left: 0.4rem;
  &__item {
    display: flex;
    height: auto;
    background-color: $white;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
    }
  }
  &__item-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    cursor: pointer;
  }
  .-item-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .-wrapper {
    flex: 0 0 auto;
    @include aspect-ratio(4, 3);
    overflow: hidden;
    object-fit: cover;
    iframe {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .-img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 0.7rem;
    border-top-right-radius: 0.7rem;
    text-align: center;
  }

  .-text-wrapper {
    flex: 1 1 auto;
    width: 100%;
    height: 8.5rem;
    padding: 0.5rem 0.5rem 0 0.5rem;
    background-color: $white;
    &.-no-img {
      height: calc(11.25rem + 8.5rem);
    }
  }
  .-head-no-link {
    font-weight: 600;
    color: $gray-900;
    font-size: 1rem;
  }
  .-head {
    font-weight: 600;
    color: $primary;
    font-size: 1rem;
  }
  .-text {
    color: $body-color;
    width: 100%;
    text-overflow: ellipsis;
    hyphens: auto;
    a {
      color: $body-color;
      text-decoration: underline;
    }
  }
  .-link-wrapper {
    position: relative;
    flex: 0 0 auto;
    height: 1.7rem;
    margin: 1.4rem 1rem 0.5rem 1rem;
  }
  .-link {
    font-weight: 600;
    text-align: right;
    a {
      @extend .-icon-arrow-right;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.2rem 0;
      border-radius: 0.2rem;
      text-align: center;
      background-color: var(--brand-primary, var(--partner-primary));
      a {
        color: $white;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
  // ----------------------------------------------------------------
  // autoheight: all items have the same height
  // ----------------------------------------------------------------
  &.-autoheight {
    .-text-wrapper {
      height: auto;
    }
    .-link-wrapper {
      margin: 0.7rem 1rem 0.5rem 1rem;
    }
  }
}

// ----------------------------------------------------------------
// static slide container if not enough slides
// ----------------------------------------------------------------
.mink-slider-sm-inactive {
  display: flex;
  overflow: hidden;
  gap: 16px;
  margin-left: 5px;
  cursor: auto;
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-slider-sm {
    // left: 0;
    // width: 100%;
    &__item {
      @include media-breakpoint-up(lg) {
      }
    }
    &__item-link {
      position: absolute;
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      z-index: 5;
      cursor: pointer;
    }
    .-img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .-text-wrapper {
      flex: 1 1 auto;
      width: 100%;
      height: 10.5rem;
      padding: 0.5rem 0.5rem 0 0.5rem;
      background-color: $white;
      &.-no-img {
        height: auto;
      }
    }
    .-head-no-link {
      font-weight: 700;
      color: map-get($theme-colors, "sanoa-primary");
      font-size: 1rem;
    }
    .-head {
      font-weight: 700;
      color: map-get($theme-colors, "sanoa-primary");
      font-size: 1rem;
      a {
        color: map-get($theme-colors, "sanoa-primary");
        &:hover {
          text-decoration: none;
        }
      }
    }
    .-text {
      color: map-get($theme-colors, "sanoa-primary");
      width: 100%;
      text-overflow: ellipsis;
      hyphens: auto;
      a {
        color: map-get($theme-colors, "sanoa-primary");
        text-decoration: underline;
      }
    }
    .-link-wrapper {
      position: relative;
      flex: 0 0 auto;
      height: 1.7rem;
      margin: 1.4rem 1rem 0.5rem 1rem;
    }
    .-link {
      text-align: right;
      padding: 0.2rem 0;
      border-radius: 0;
      background-color: transparent;
      a {
        @extend .sn-link;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
}
