.btn:not(.btn-link) {
  &:hover {
    color: $white;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  }
}

.btn-reset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  outline: none;
  color: initial;
  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
    color: initial;
  }
  &:hover {
    text-decoration: none;
  }
}

.btn-cta {
  @extend .btn;
  color: #fff;
  background-color: $cta-color;
  border: none;
  border-radius: 0.75rem;
  box-shadow: $btn-shadow;
  cursor: pointer;
  display: inline-block;
  padding: 0.875rem 1rem;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  width: 100%;
  font-weight: 600;
  letter-spacing: -0.1px;
  &:hover {
    background-color: $cta-dark-color;
  }
}

.mink-button-text-icon {
  @extend .btn-reset;
  background-color: $green;
  color: $white;
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: -0.3;
  padding: 0 0 0 1rem;
  border-radius: 1.5rem;
  line-height: 1;
  vertical-align: middle;
  height: 3rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  .-text {
    position: relative;
    display: inline-block;
    padding: 0 3rem 0 0;
    &::after {
      content: "";
      position: absolute;
      right: 0.5rem;
      top: -0.4rem;
      bottom: 0;
      height: 32px;
      width: 32px;
      background: no-repeat center/contain;
    }
  }
}

.mink-cta-button {
  margin-bottom: 3rem;
  .btn-cta {
    @extend .btn;
    color: #fff;
    background-color: $cta-color;
    border: none;
    border-radius: 0.75rem;
    box-shadow: $btn-shadow;
    cursor: pointer;
    display: inline-block;
    padding: 0.875rem 1rem;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
    width: 100%;
    font-weight: 600;
    letter-spacing: -0.1px;
    &:hover {
      background-color: $cta-dark-color;
    }
    &.noclick {
      &:active,
      &:hover {
        background-color: $cta-color !important;
      }
      box-shadow: none;
      cursor: auto;
      user-select: text;
    }
  }
}

.mink-close-button {
  &.-button {
    @extend .btn-reset;
    background-color: $white;
  }
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 45px;
  height: 45px;
  background-color: $white;
  z-index: 10;
  border-radius: 50%;
}
.mink-close-button-text {
  &.-button {
    @extend .mink-button-text-icon;
    .-text {
      &::after {
        background-image: $icon-close;
      }
    }
  }
  position: fixed;
  left: 50%;
  bottom: 1rem;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  background-color: $green;
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-menu-btn {
  border-radius: 0;
  color: map-get($theme-colors, "sanoa-primary");
  font-weight: 600;
  line-height: 1;
  @include media-breakpoint-up(md) {
    border-left: $border-width solid map-get($theme-colors, "sanoa-primary");
    padding: 0 0 0 1rem;
  }
  &.open,
  &.current-path,
  &:hover,
  &:focus {
    color: map-get($theme-colors, "sanoa-primary");
    text-decoration: underline;
    text-decoration-color: map-get($theme-colors, "sanoa-orange");
    text-decoration-style: solid;
    text-decoration-thickness: $border-width;
    text-underline-offset: 0.25rem;
  }
}
.mink-btn {
  background-color: $white;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  font-weight: 700;
  border: 0.125rem solid map-get($theme-colors, "sanoa-blue");
  border-radius: $border-button-radius;
  font-family: $font-portal;
  color: map-get($theme-colors, "sanoa-blue");
  padding: 0.5rem;
  &:hover {
    background-color: $white;
    color: map-get($theme-colors, "sanoa-blue");
    box-shadow: $btn-shadow;
    text-decoration: none;
  }
}

.mink-btn-colored {
  @extend .mink-btn;
  color: $white;
  background-color: map-get($theme-colors, "sanoa-button");
  border: 0.125rem solid map-get($theme-colors, "sanoa-button");
  &:hover {
    color: $white;
    background-color: map-get($theme-colors, "sanoa-button");
    border: 0.125rem solid map-get($theme-colors, "sanoa-button");
    box-shadow: $btn-shadow;
  }
}
