.mink-brand-header {
  &__background {
    height: 80px;
    width: 100%;
    position: absolute;
    top: 0px;
    background-color: white;
    // border-bottom: $border-header;
    box-shadow: 0 0 0.3rem rgba($black, 0.75);
  }
  &__content {
    width: 100%;
    height: 80px;
    background: var(--brand-secondary);
    position: relative;
    h1 {
      color: black;
      padding-top: 0.7rem;
      text-align: end;
    }
    &_text {
      .ida-header__address {
        margin: auto 0px;
      }
    }
  }
  &__address_mobile {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: hsla(0, 0%, 100%, 0.7);
    text-align: right;
  }

  .ida-header {
    &__menu-button {
      margin-right: 1.5rem;
      border-color: $black;

      span {
        background-color: $black;
      }
    }
    &__menu {
      background-color: $white;
      color: $black;
      @include media-breakpoint-up(lg) {
        margin-right: 0;
        border-bottom-right-radius: 0;
      }
      .nav_item {
        .btn-link {
          color: $black;
        }
      }
      a,
      .main-menu-link {
        color: $black;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -7px;
        display: block;
        width: 100%;
        height: 7px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
      }
    }
  }
}
