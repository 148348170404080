// -------------------------------------------------------------------------
// ACF Agenda: Display custom icons and text as steps
// -------------------------------------------------------------------------
.mink-agenda {
  margin-bottom: 2rem;
  counter-reset: sanoa-counter;
  .mink-agenda-item {
    position: relative;
    counter-increment: sanoa-counter;
    &__header {
      display: flex;
      align-items: center;
      .-icon {
        position: relative;
        z-index: 10;
        width: 3.25rem;
        height: 3.25rem;
        margin: 0 0.625rem;
        background-color: var(--brand-primary, var(--partner-primary));
        box-shadow: $box-shadow;
        border-radius: 50%;
        @include media-breakpoint-up(sm) {
          margin: 0 2.5rem;
        }
        &::before {
          position: absolute;
          width: 3.25rem;
          height: 3.25rem;
          top: 0;
          left: 0;
          text-align: center;
          padding-top: 0.9rem;
          content: counter(sanoa-counter);
          color: $white;
          font-weight: bold;
          z-index: 20;
        }
        img {
          position: relative;
          z-index: 30;
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: var(--brand-primary, var(--partner-primary));
          border-radius: 50%;
        }
      }
      .-title {
        margin: 0;
        color: var(--brand-primary, var(--partner-primary));
      }
    }
    &__content {
      margin: 0.75rem 0.625rem 0.75rem 2.25rem;
      border-left: 0.188rem solid var(--brand-primary, var(--partner-primary));
      padding-left: 2.188rem;
      p {
        margin-bottom: 0.75rem;
      }
      @include media-breakpoint-up(sm) {
        padding-left: 3.938rem;
        margin: 0.75rem 0 0.75rem 4.125rem;
      }
    }
    &:last-child {
      .mink-agenda-item__content {
        border-left: 0.188rem solid rgba(255, 255, 255, 0);
      }
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-agenda {
    @include media-breakpoint-up(lg) {
      width: 66%;
    }
    .mink-agenda-item {
      &__header {
        .-icon {
          margin: 0 0.625rem 0 0;
          background-color: map-get($theme-colors, "sanoa-green-portal");
          @include media-breakpoint-up(sm) {
            margin: 0 2.5rem 0 0;
          }
          &::before {
            top: 0;
            left: 0;
          }
          img {
            background-color: map-get($theme-colors, "sanoa-green-portal");
          }
        }
        .-title {
          color: map-get($theme-colors, "sanoa-green-portal");
        }
      }
      &__content {
        margin: 0.75rem 0.625rem 0.75rem 1.5rem;
        border-left: 0.188rem solid map-get($theme-colors, "sanoa-green-portal");
        padding-left: 2.188rem;
        @include media-breakpoint-up(sm) {
          padding-left: 3.938rem;
          margin: 0.75rem 0 0.75rem 1.6rem;
        }
        a {
          @extend .sn-link;
          position: relative;
          font-weight: 600;
        }
      }
    }
  }
}
