// -----------------------------------------------------------------
// ACF Top Content: Introtext with opt. logo
// -----------------------------------------------------------------
.mink-top-content {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
  &__content {
    order: 2;
    width: 100%;
    @include media-breakpoint-up(lg) {
      order: 1;
      &.-logo {
        width: 80%;
      }
    }
  }
  &__logo {
    order: 1;
    text-align: right;
    padding: 0 0 1rem 1rem;
    img {
      max-width: 12rem;
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      order: 2;
      width: 20%;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  h2 {
    margin-bottom: 0.5rem;
  }
  p {
    padding: 0 0 0 0.7rem;
  }
}
