// -----------------------------------------------
// ACF Embed Video: displays a Youtube/Vimeo video
// -----------------------------------------------
.mink-video {
  background-color: $white;
  margin: 0 0.5rem 1.5rem 0;
  @include media-breakpoint-up(lg) {
    margin: 0 1rem 2rem 0;
  }

  .-text-wrapper {
    padding: 0.5rem 0.75rem 0 0.75rem;
  }
  .-head {
    font-weight: 600;
    color: $primary;
    font-size: 1rem;
  }
  .-text {
    color: $body-color;
    hyphens: auto;
    a {
      color: $body-color;
      text-decoration: underline;
    }
    ul {
      padding: 0rem 0 0.5rem 1.2rem;
    }
  }

  .-link {
    margin: 0 0.75rem 0.5rem 0.75rem;
    font-weight: 600;
    text-align: right;
    a {
      @extend .-icon-arrow-right;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.2rem 0;
      border-radius: 0.2rem;
      text-align: center;
      background-color: var(--brand-primary, var(--partner-primary));
      a {
        color: $white;
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-video {
    @include media-breakpoint-up(lg) {
      width: 66%;
    }
  }
}
