// -------------------------------------------------------------------------
// ACF List: accodeon list with collapse part to hide entries
// on mobile all entries hidden, only captions visible
// on desktop caption and text visible, limited entries visible
// threshold configurable
// -------------------------------------------------------------------------
.mink-list {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 1rem 0.5rem 0.5rem 1rem;
  margin: 0 0 2rem 0;
  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 0.5rem 0;
    padding: 0 0 0 1.2rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    &::before {
      position: absolute;
      content: "\2013";
      display: inline-block;
      left: 0;
      top: 0;
      line-height: 1;
      font-size: 1.5rem;
      color: var(--primary);
    }
    h4 {
      color: var(--primary);
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 600;
      margin: 0;
      @include media-breakpoint-up(lg) {
        width: 33%;
        min-width: 33%;
        padding-right: 0.5rem;
      }
    }
    .-link {
      font-weight: 600;
    }
  }
  &__content {
    padding: 1rem 0;
    @include media-breakpoint-up(lg) {
      padding: 0;
      margin-left: 0;
    }
    p {
      margin: 0;
    }
  }
  .data {
    display: none;
  }
}

// ----------------------------------------------------------------
// new sanoa era starts with the portal
// ----------------------------------------------------------------
.sn-portal {
  .mink-list {
    box-shadow: none;
    border-radius: 0;
    padding: 1rem 0.5rem 0.5rem 0;
    margin: 0 0 2rem 0;
    @include media-breakpoint-up(lg) {
      width: 66%;
    }
    &__item {
      flex-direction: column;
      &::before {
        display: none;
        position: absolute;
        content: "\2013";
        left: 0;
        top: 0;
        line-height: 1;
        font-size: 1.5rem;
        color: map-get($theme-colors, "sanoa-primary");
      }
      h4 {
        @extend h4;
        margin-bottom: 0.8rem;
        @include media-breakpoint-up(lg) {
          width: 100%;
          min-width: 100%;
          padding-right: 0.5rem;
        }
      }

      .-link {
        @extend .sn-link;
        @extend h4;
      }
    }
    &__content {
      p {
        padding: 0 0 1.5rem 0;
        margin: 0;
      }
      a {
        @extend .sn-link;
        position: relative;
        font-weight: 600;
      }
    }
  }
}
