.iframe-block {
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  &.google-maps,
  &.-appointments {
    background-image: url($image-path + "map_blurred.jpg");
    .iframe-block__caption {
      color: $body-color;
      a {
        color: $body-color;
      }
    }
  }
  &.-appointments {
    margin-bottom: 2rem;
    background-image: url($image-path + "calendar_blurred.jpg");
  }
  &.youtube {
    .iframe-block__caption {
      color: $white;
      font-size: 0.8rem;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0.8rem 0.5rem;
      border-radius: 1rem;
      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
      a {
        color: $white;
      }
    }
    .iframe-block__button {
      .-text {
        color: $white;
        &::after {
          background-image: $icon-arrow-right-white;
        }
      }
    }
  }
  &.-finder {
    .iframe-block__button {
      background-color: $white;
      cursor: pointer;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      overflow-wrap: break-word;
      font-weight: 700;
      border: 0.125rem solid map-get($theme-colors, "sanoa-blue");
      font-family: $font-portal;
      color: map-get($theme-colors, "sanoa-blue");
      // padding: 0.5rem;
      height: 4rem;
      border-radius: 2.25rem;
    }
  }
  &.-third-party-app {
    position: relative;
    .privacy-text {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 2rem;
      background-color: var(--partner-primary, $sanoa-light-blue);
      color: $white;
      font-weight: 600;
      padding: 0.25rem 3.75rem 0.25rem 1rem;
      a {
        color: $white;
        font-weight: normal;
      }
      p {
        margin: 0;
        padding: 0;
      }
      &.-closed {
        display: none;
      }
      @include media-breakpoint-up(md) {
        padding: 1rem 3.75rem 1rem 1rem;
        border-radius: 1.5rem 1.5rem 0 0;
      }
    }
    .iframe-block__button {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: var(--partner-primary, $sanoa-light-blue);
      padding: 0;
      background-image: $icon-third-party;
      background-size: contain;
      width: 3.5rem;
      height: 3.5rem;
      text-indent: -9999em;
      border: $border-width solid $white;
      border-radius: 50%;
      @include media-breakpoint-up(md) {
        bottom: 0.25rem;
        // border-radius: 1.5rem 1.5rem 0 0;
        // box-shadow: none;
      }
      &.-open {
        // box-shadow: none;
      }
      .-text {
        height: 100%;
        width: 100%;
        &::after {
          display: none;
        }
      }
    }
  }

  &__caption {
    margin: 0 auto;
    text-align: center;
    &.btn-sm {
      font-size: 0.8rem;
      align-self: flex-end;
      a {
        font-size: 0.8rem;
      }
    }
    .-blocker-text {
      // display: inline-block;
      margin: 1rem 1rem 0;
      a {
        text-decoration: underline;
      }
    }
  }

  .btn.btn-sm {
    align-self: flex-end;
    font-size: 0.8rem;
    padding: 0.8rem 1.6rem 0.9rem 0.9rem;
  }

  &__button {
    @extend .mink-button-text-icon;
    .-text {
      &::after {
        background-image: $icon-check;
      }
    }
  }
}

.modal-backdrop {
  background-color: $black;
}
.mink-consent-modal {
  .modal-content {
    border-radius: 1rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      padding-left: 0;
    }
  }
  &__root {
    position: relative;
    display: block;
    z-index: 1050;
  }
  &__footer {
    justify-content: center;
    .btn-primary {
      @extend .mink-button-text-icon;
      .-text {
        &::after {
          background-image: $icon-check;
          // height: 26px;
        }
      }
      &:hover,
      &:active {
        background-color: $green;
        color: $white;
      }
    }
    .btn-light {
      // @extend .btn-reset;
      background-color: transparent;
      color: $body-color;
      letter-spacing: -0.3;
      border: none;
      border-radius: 1.1rem;
      padding: 0 1rem;
      line-height: 1;
      vertical-align: middle;
      height: 3rem;
      box-shadow: none;
      &:hover,
      &:active {
        background-color: transparent;
        color: $body-color;
      }
    }
  }
}
