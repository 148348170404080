.acf-locator {
  position: relative;
  color: map-get($theme-colors, "sanoa-blue");
  z-index: 5;
  font-family: $font-portal;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(md) {
    max-width: 100% !important;
  }
  @include media-breakpoint-up(lg) {
    display: block;
    margin-bottom: 1.5rem;
    &.-filter {
      margin-bottom: 0;
    }
  }
  &__panel {
    z-index: 20;
    display: flex;
    justify-content: center;
    margin: 0 0 1rem 0;
    @include media-breakpoint-up(lg) {
      position: absolute !important;
      top: 0.5rem;
      left: 0;
      margin: 0 5rem 0 13rem;
      width: calc(100% - 18rem);
    }
  }
  &__autocomplete {
    border: 0.125rem solid map-get($theme-colors, "sanoa-blue");
    border-radius: $border-button-radius;
    font-family: $font-portal;
    color: map-get($theme-colors, "sanoa-blue");
    padding: 0.5rem;
    margin: 0 1rem 0 0;
    width: 100%;
    max-width: 27.5rem;
    background: #fff;
    align-self: flex-start;
    transition: all 0.25s ease-out;
    .start-with-search & {
      margin: 0;
      transform: translateY(10rem);
      @include media-breakpoint-up(lg) {
        transform: scale(1.3) translateY(10rem);
      }
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      color: map-get($theme-colors, "sanoa-blue");
      box-shadow: $btn-shadow;
    }
    .autocomplete-control {
      min-height: auto !important;
      align-items: center;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      outline: 0 !important;
      position: relative;
      transition: all 100ms;
      box-sizing: border-box;
    }
    .autocomplete-container {
      align-items: center;
      display: grid;
      flex: 1;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
    }
    .autocomplete-menu {
      top: 3rem !important;
      left: 0;
      padding: 0.5rem;
      background-color: $white;
      box-shadow: $box-shadow;
      color: map-get($theme-colors, "sanoa-blue");
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    .autocomplete-placeholder {
      grid-area: 1/1/2/3;
      box-sizing: border-box;
      font-size: 0.8rem;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    .autocomplete-input {
      visibility: visible;
      flex: 1 1 auto;
      display: inline-grid;
      grid-area: 1/1/2/3;
      grid-template-columns: 0 min-content;
      box-sizing: border-box;
      &:after {
        content: attr(data-value) " ";
        visibility: hidden;
        white-space: pre;
        grid-area: 1/2;
        font: inherit;
        min-width: 2px;
        border: 0;
        margin: 0;
        outline: 0;
        padding: 0;
      }
    }
  }
  &__toggle-map {
    width: 4rem;
    @include media-breakpoint-up(lg) {
      // width: 13rem;
    }
    .start-with-search & {
      display: none;
    }
    .-map {
      display: block;
      text-indent: -9999em;
      background-image: $icon-map;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .-list {
      display: none;
      text-indent: -9999em;
      background-image: $icon-list;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.-active {
      .-map {
        display: none;
      }
      .-list {
        display: block;
      }
    }
  }

  &__map {
    position: relative;
    display: none;
    top: 0;
    width: 100%;
    height: 50vh;
    z-index: 10;
    // hide some ui elements
    .gm-style-mtc-bbw {
      display: none;
    }
    .gm-svpc {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      margin: 0;
      height: 34.5rem;
      .gm-style-mtc-bbw {
        display: flex;
      }
    }
    &.-active {
      display: block;
    }
    .cluster * {
      color: $white !important;
    }
  }
  &__search-here {
    position: absolute;
    bottom: 1rem;
    z-index: 10;
    width: calc(100% - 8rem);
    margin: 0 4rem;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    .start-with-search & {
      display: none;
    }
  }
  &__list {
    display: none;
    position: relative;
    top: 0;
    width: 100%;
    height: 50vh;
    background-color: $white;
    z-index: 10;
    overflow-y: auto;
    @include media-breakpoint-up(lg) {
      height: 34.5rem;
      padding-top: 4rem;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      margin: 0 0 0.5rem 0;
      &::before {
        position: absolute;
        content: none;
        color: inherit;
        display: inline-block;
        width: 0;
        left: -1.2rem;
        line-height: 1;
        font-size: 1.5rem;
      }
    }
    &.-active {
      display: block;
    }
  }
  &__list-item {
    display: flex;
    .-logo {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 8rem;
      @include media-breakpoint-up(lg) {
        width: 10rem;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .-text {
      width: 100%;
      margin: 0 0 0 1rem;
      padding: 0.5rem;
      background-color: map-get($theme-colors, "sanoa-secondary");
      h3 {
        padding: 0;
        color: map-get($theme-colors, "sanoa-primary");
        font-size: 1.1rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }
      }
    }
  }
  &__filter {
    display: grid;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem 0 0 0;
    @include media-breakpoint-up(lg) {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      width: 100%;
      z-index: 20;
      flex-direction: row;
      margin-top: 0;
    }
    .-caption {
      padding: 0 0 0.5rem 0;
      @include media-breakpoint-up(lg) {
        padding: 0.5rem 0 0 0;
      }
    }
    .-item {
      margin: 0 0 0.5rem 0;
      @include media-breakpoint-up(lg) {
        margin: 0 0 0.5rem 1rem;
      }
    }
  }
  // &__info-window {
  //   display: flex;
  //   flex-direction: column;
  //   font-family: $font-portal;
  //   font-size: 0.875rem;
  //   height: 350px;
  //   @include media-breakpoint-up(md) {
  //     flex-direction: row;
  //   }
  //   .location-thumb {
  //     display: flex;
  //     align-items: flex-start;
  //     padding: 0 0 0.5rem 0;
  //     @include media-breakpoint-up(md) {
  //       // width: 33%;
  //       // padding: 0 0.5rem 0 0;
  //     }
  //     img {
  //       width: 100%;
  //       height: auto;
  //     }
  //   }
  //   .location-detail {
  //     @include media-breakpoint-up(md) {
  //       // width: 66%;
  //       // &.non-partner {
  //       //   width: 100%;
  //       // }
  //     }
  //     &-head {
  //       padding-right: 1rem;
  //     }
  //     p {
  //       margin-bottom: 0.5rem;
  //     }
  //     .location-address,
  //     .location-phone {
  //       margin-bottom: 0.25rem;
  //     }
  //     .location-service-head {
  //       margin: 1rem 0 0.125rem 0;
  //     }
  //     .location-services {
  //       height: 3rem;
  //       overflow: hidden;
  //       &.-active {
  //         height: auto;
  //         overflow: auto;
  //       }
  //       &__toggle {
  //         position: relative;
  //         margin: 0.5rem 0 0 0;
  //         padding: 0 0 0 1rem;
  //         font-weight: 600;
  //         cursor: pointer;
  //         &::before {
  //           content: "";
  //           position: absolute;
  //           top: 0.2rem;
  //           left: 0;
  //           width: 0.8rem;
  //           height: 0.8rem;
  //           display: inline-block;
  //           background: no-repeat center;
  //           background-image: $icon-collapse-open;
  //           background-size: contain;
  //         }
  //         &.open {
  //           &::before {
  //             background-image: $icon-collapse-close;
  //           }
  //         }
  //         &:focus {
  //           border: none;
  //           outline: none;
  //         }
  //       }
  //     }
  //     .location-brand-head {
  //       margin: 1rem 0 0.125rem 0;
  //     }
  //     .location-brand-logos {
  //       margin-bottom: 1rem;
  //       max-width: 20rem;
  //       display: flex;
  //       flex-direction: row;
  //       flex-wrap: wrap;

  //       .location-brand-logo {
  //         padding: 0 0.5rem 0.5rem 0;
  //         max-width: 5rem;
  //         a {
  //           display: flex;
  //           align-items: center;
  //           height: 100%;
  //         }
  //         img {
  //           width: 100%;
  //           height: auto;
  //         }
  //       }
  //     }
  //     .location-button {
  //       margin-top: 1rem;
  //     }
  //     &.non-partner {
  //       .location-address,
  //       .location-phone {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}

// google info window
.gm-style {
  .gm-style-iw-c {
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    -webkit-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0);
    background-color: white;
    border-radius: 0;
    padding: 12px;
    -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  }
  .gm-style-iw-tc {
    -webkit-filter: drop-shadow(0 4px 2px rgba(178, 178, 178, 0.4));
    filter: drop-shadow(0 4px 2px rgba(178, 178, 178, 0.4));
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 25px;
    &::after {
      display: none;
    }
  }
  .gm-style-iw-chr {
    position: relative;
    button {
      position: absolute !important;
      top: 0.25rem;
      right: 0.3rem;
      // width: 1rem;
      // height: 1rem;
      span {
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
        mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'%3E%3Cpath d='M14.209 14.207l-13.5 13.5 13.5-13.5L.709.707l13.5 13.5 13.5-13.5-13.5 13.5 13.5 13.5z' style='mix-blend-mode:multiply;isolation:isolate' fill='%234a4a4a' stroke='%234a4a4a' stroke-width='2'/%3E%3C/svg%3E") !important;
        background-size: contain;
      }
    }
  }
}
.single-sanoa_embed {
  .mink-embed-container {
    min-height: 500px;
  }
  .acf-locator {
    margin-bottom: 0;
    .acf-locator__map {
      height: 516px;
    }
    &__list {
      height: 516px;
    }
  }
}

// maps
/*
.css-1f43avz-a11yText-A11yText {
  z-index: 9999;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}

.css-1hb7zxy-IndicatorsContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.css-j4w2j1-indicatorSeparator {
  align-self: stretch;
  width: 1px;
  box-sizing: border-box;
}
.css-g56vrd-indicatorContainer {
  display: flex;
  transition: color 150ms;
  box-sizing: border-box;
}
.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.gm-style .transit-container {
  background-color: white;
  max-width: 265px;
  overflow-x: hidden;
}
.gm-style .transit-container .transit-title span {
  font-size: 14px;
  font-weight: 500;
}
.gm-style .transit-container .transit-title {
  padding-bottom: 6px;
}
.gm-style .transit-container .transit-wheelchair-icon {
  background: transparent url(https://maps.gstatic.com/mapfiles/api-3/images/mapcnt6.png);
  -webkit-background-size: 59px 492px;
  background-size: 59px 492px;
  display: inline-block;
  background-position: -5px -450px;
  width: 13px;
  height: 13px;
}
@media (-webkit-min-device-pixel-ratio: 1.2),
  (-webkit-min-device-pixel-ratio: 1.2083333333333333),
  (min-resolution: 1.2dppx),
  (min-resolution: 116dpi) {
  .gm-style .transit-container .transit-wheelchair-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/mapcnt6_hdpi.png);
    -webkit-background-size: 59px 492px;
    background-size: 59px 492px;
    display: inline-block;
    background-position: -5px -449px;
    width: 13px;
    height: 13px;
  }
  .gm-style.gm-china .transit-container .transit-wheelchair-icon {
    background-image: url(http://maps.gstatic.cn/mapfiles/api-3/images/mapcnt6_hdpi.png);
  }
}
.gm-style .transit-container div {
  background-color: white;
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
}
.gm-style .transit-container .transit-line-group {
  overflow: hidden;
  margin-right: -6px;
}
.gm-style .transit-container .transit-line-group-separator {
  border-top: 1px solid #e6e6e6;
  padding-top: 5px;
}
.gm-style .transit-container .transit-nlines-more-msg {
  color: #999;
  margin-top: -3px;
  padding-bottom: 6px;
}
.gm-style .transit-container .transit-line-group-vehicle-icons {
  display: inline-block;
  padding-right: 10px;
  vertical-align: top;
  margin-top: 1px;
}
.gm-style .transit-container .transit-line-group-content {
  display: inline-block;
  min-width: 100px;
  max-width: 228px;
  margin-bottom: -3px;
}
.gm-style .transit-container .transit-clear-lines {
  clear: both;
}
.gm-style .transit-container .transit-div-line-name {
  float: left;
  padding: 0 6px 6px 0;
  white-space: nowrap;
}
.gm-style .transit-container .transit-div-line-name .gm-transit-long {
  width: 107px;
}
.gm-style .transit-container .transit-div-line-name .gm-transit-medium {
  width: 50px;
}
.gm-style .transit-container .transit-div-line-name .gm-transit-short {
  width: 37px;
}
.gm-style .transit-div-line-name .renderable-component-icon {
  float: left;
  margin-right: 2px;
}
.gm-style .transit-div-line-name .renderable-component-color-box {
  background-image: url(https://maps.gstatic.com/mapfiles/transparent.png);
  height: 10px;
  width: 4px;
  float: left;
  margin-top: 3px;
  margin-right: 3px;
  margin-left: 1px;
}
.gm-style.gm-china .transit-div-line-name .renderable-component-color-box {
  background-image: url(http://maps.gstatic.cn/mapfiles/transparent.png);
}
.gm-style .transit-div-line-name .renderable-component-text,
.gm-style .transit-div-line-name .renderable-component-text-box {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.gm-style .transit-div-line-name .renderable-component-text-box {
  font-size: 8pt;
  font-weight: 400;
  text-align: center;
  padding: 1px 2px;
}
.gm-style .transit-div-line-name .renderable-component-text-box-white {
  border: solid 1px #ccc;
  background-color: white;
  padding: 0 2px;
}
.gm-style .transit-div-line-name .renderable-component-bold {
  font-weight: 400;
}
sentinel {
}
.poi-info-window div,
.poi-info-window a {
  color: #333;
  font-family: Roboto, Arial;
  font-size: 13px;
  background-color: white;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.poi-info-window {
  cursor: default;
}
.poi-info-window a:link {
  text-decoration: none;
  color: #1a73e8;
}
.poi-info-window .view-link,
.poi-info-window a:visited {
  color: #1a73e8;
}
.poi-info-window .view-link:hover,
.poi-info-window a:hover {
  cursor: pointer;
  text-decoration: underline;
}
.poi-info-window .full-width {
  width: 180px;
}
.poi-info-window .title {
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
}
.poi-info-window .address {
  margin-top: 2px;
  color: #555;
}
sentinel {
}
.gm-style .gm-style-iw {
  font-weight: 300;
  font-size: 13px;
  overflow: hidden;
}
.gm-style .gm-style-iw-a {
  position: absolute;
  width: 9999px;
  height: 0;
}
.gm-style .gm-style-iw-t {
  position: absolute;
  width: 100%;
}
.gm-style .gm-style-iw-tc {
  -webkit-filter: drop-shadow(0 4px 2px rgba(178, 178, 178, 0.4));
  filter: drop-shadow(0 4px 2px rgba(178, 178, 178, 0.4));
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 25px;
}
.gm-style .gm-style-iw-tc::after {
  background: #fff;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}
.gm-style .gm-style-iw-c {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  -webkit-box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}
.gm-style .gm-style-iw-d {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar {
  width: 18px;
  height: 12px;
  -webkit-appearance: none;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background: #ffffff;
}
.gm-style .gm-style-iw-c .gm-style-iw-d::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.12);
  border: 6px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}
.gm-style .gm-style-iw-c .gm-style-iw-d::-webkit-scrollbar-thumb:horizontal {
  border: 3px solid transparent;
}
.gm-style .gm-style-iw-c .gm-style-iw-d::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.gm-style .gm-style-iw-c .gm-style-iw-d::-webkit-scrollbar-corner {
  background: transparent;
}
.gm-style .gm-iw {
  color: #2c2c2c;
}
.gm-style .gm-iw b {
  font-weight: 400;
}
.gm-style .gm-iw a:link,
.gm-style .gm-iw a:visited {
  color: #4272db;
  text-decoration: none;
}
.gm-style .gm-iw a:hover {
  color: #4272db;
  text-decoration: underline;
}
.gm-style .gm-iw .gm-title {
  font-weight: 400;
  margin-bottom: 1px;
}
.gm-style .gm-iw .gm-basicinfo {
  line-height: 18px;
  padding-bottom: 12px;
}
.gm-style .gm-iw .gm-website {
  padding-top: 6px;
}
.gm-style .gm-iw .gm-photos {
  padding-bottom: 8px;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.gm-style .gm-iw .gm-sv,
.gm-style .gm-iw .gm-ph {
  cursor: pointer;
  height: 50px;
  width: 100px;
  position: relative;
  overflow: hidden;
}
.gm-style .gm-iw .gm-sv {
  padding-right: 4px;
}
.gm-style .gm-iw .gm-wsv {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.gm-style .gm-iw .gm-sv-label,
.gm-style .gm-iw .gm-ph-label {
  cursor: pointer;
  position: absolute;
  bottom: 6px;
  color: #ffffff;
  font-weight: 400;
  text-shadow: rgba(0, 0, 0, 0.7) 0 1px 4px;
  font-size: 12px;
}
.gm-style .gm-iw .gm-stars-b,
.gm-style .gm-iw .gm-stars-f {
  height: 13px;
  font-size: 0;
}
.gm-style .gm-iw .gm-stars-b {
  position: relative;
  background-position: 0 0;
  width: 65px;
  top: 3px;
  margin: 0 5px;
}
.gm-style .gm-iw .gm-rev {
  line-height: 20px;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.gm-style .gm-iw .gm-numeric-rev {
  font-size: 16px;
  color: #dd4b39;
  font-weight: 400;
}
.gm-style .gm-iw.gm-transit {
  margin-left: 15px;
}
.gm-style .gm-iw.gm-transit td {
  vertical-align: top;
}
.gm-style .gm-iw.gm-transit .gm-time {
  white-space: nowrap;
  color: #676767;
  font-weight: bold;
}
.gm-style .gm-iw.gm-transit img {
  width: 15px;
  height: 15px;
  margin: 1px 5px 0 -20px;
  float: left;
}
sentinel {
}
.gm-iw {
  text-align: left;
}
.gm-iw .gm-numeric-rev {
  float: left;
}
.gm-iw .gm-photos,
.gm-iw .gm-rev {
  direction: ltr;
}
.gm-iw .gm-stars-f,
.gm-iw .gm-stars-b {
  background: url("https://maps.gstatic.com/mapfiles/api-3/images/review_stars_hdpi.png") no-repeat;
  background-size: 65px 26px;
  float: left;
}
.gm-iw .gm-stars-f {
  background-position: left -13px;
}
.gm-iw .gm-sv-label,
.gm-iw .gm-ph-label {
  left: 4px;
}
.ssQIHO-checkbox-menu-item > span > span {
  background-color: #000;
  display: inline-block;
}
@media (forced-colors: active), (prefers-contrast: more) {
  .ssQIHO-checkbox-menu-item > span > span {
    background-color: ButtonText;
  }
}
.gm-style .gm-style-mtc label,
.gm-style .gm-style-mtc div {
  font-weight: 400;
}
.gm-style .gm-style-mtc ul,
.gm-style .gm-style-mtc li {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.gm-style-mtc-bbw {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.gm-style-mtc-bbw .gm-style-mtc:first-of-type > button {
  border-start-start-radius: 2px;
  border-end-start-radius: 2px;
}
.gm-style-mtc-bbw .gm-style-mtc:last-of-type > button {
  border-start-end-radius: 2px;
  border-end-end-radius: 2px;
}
sentinel {
}
.LGLeeN-keyboard-shortcuts-view {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.LGLeeN-keyboard-shortcuts-view table,
.LGLeeN-keyboard-shortcuts-view tbody,
.LGLeeN-keyboard-shortcuts-view td,
.LGLeeN-keyboard-shortcuts-view tr {
  background: inherit;
  border: none;
  margin: 0;
  padding: 0;
}
.LGLeeN-keyboard-shortcuts-view table {
  display: table;
}
.LGLeeN-keyboard-shortcuts-view tr {
  display: table-row;
}
.LGLeeN-keyboard-shortcuts-view td {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: table-cell;
  color: #000;
  padding: 6px;
  vertical-align: middle;
  white-space: nowrap;
}
.LGLeeN-keyboard-shortcuts-view td:first-child {
  text-align: end;
}
.LGLeeN-keyboard-shortcuts-view td kbd {
  background-color: #e8eaed;
  border-radius: 2px;
  border: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: inline-block;
  font-family: Google Sans Text, Roboto, Arial, sans-serif;
  line-height: 16px;
  margin: 0 2px;
  min-height: 20px;
  min-width: 20px;
  padding: 2px 4px;
  position: relative;
  text-align: center;
}
.gm-control-active > img {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: none;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.gm-control-active > img:nth-child(1) {
  display: block;
}
.gm-control-active:focus > img:nth-child(1),
.gm-control-active:hover > img:nth-child(1),
.gm-control-active:active > img:nth-child(1),
.gm-control-active:disabled > img:nth-child(1) {
  display: none;
}
.gm-control-active:focus > img:nth-child(2),
.gm-control-active:hover > img:nth-child(2) {
  display: block;
}
.gm-control-active:active > img:nth-child(3) {
  display: block;
}
.gm-control-active:disabled > img:nth-child(4) {
  display: block;
}
sentinel {
}
.gm-ui-hover-effect {
  opacity: 0.6;
}
.gm-ui-hover-effect:hover {
  opacity: 1;
}
.gm-ui-hover-effect > span {
  background-color: #000;
}
@media (forced-colors: active), (prefers-contrast: more) {
  .gm-ui-hover-effect > span {
    background-color: ButtonText;
  }
}
sentinel {
}
.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span,
.gm-style .gm-style-mtc div {
  font-size: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span {
  outline-offset: 3px;
}
sentinel {
}
@media print {
  .gm-style .gmnoprint,
  .gmnoprint {
    display: none;
  }
}
@media screen {
  .gm-style .gmnoscreen,
  .gmnoscreen {
    display: none;
  }
}
.gm-style-moc {
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
  text-align: center;
  -webkit-transition: opacity ease-in-out;
  transition: opacity ease-in-out;
}
.gm-style-mot {
  color: white;
  font-family: Roboto, Arial, sans-serif;
  font-size: 22px;
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
sentinel {
}
.gm-style img {
  max-width: none;
}
.gm-style {
  font: 400 11px Roboto, Arial, sans-serif;
  text-decoration: none;
}
*/
